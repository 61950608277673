<div
  [@standardList]="field.fieldGroup?.length"
  class="list-wrapper"
>
  @for (innerField of field.fieldGroup; track trackByFn(i, innerField); let i = $index) {
  <div class="row">
    <formly-field
      [field]="innerField"
      class="field"
    ></formly-field>
    <button
      (click)="removeItem(i)"
      color="warn"
      mat-mini-fab
      type="button"
      class="remove-btn"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
  }
</div>

<div class="footer">
  <button
    (click)="addItem()"
    mat-button
    type="button"
  >
    <mat-icon>add</mat-icon>
    {{ to.addText | translate}}
  </button>
</div>
