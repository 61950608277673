<button
  [routerLink]="[base,contextId,'quick-history']"
  mat-menu-item
>
  <mat-icon>history</mat-icon>
  <span class="text">{{T.MH.QUICK_HISTORY|translate}}</span>
</button>

<button
  [routerLink]="[base,contextId,'worklog']"
  mat-menu-item
>
  <mat-icon>date_range</mat-icon>
  <span class="text">{{T.MH.WORKLOG|translate}}</span>
</button>

@if (isForProject || contextId ===TODAY_TAG_ID) {
<button
  [routerLink]="[base,contextId,'metrics']"
  mat-menu-item
>
  <mat-icon>donut_large</mat-icon>
  <span class="text">{{T.MH.METRICS|translate}}</span>
</button>
}

<button
  [routerLink]="[base,contextId,'settings']"
  mat-menu-item
>
  <mat-icon>settings</mat-icon>
  <span class="text">{{T.MH.SETTINGS|translate}}</span>
</button>

@if (!isForProject && contextId !== TODAY_TAG_ID) {
<button
  (click)="deleteTag()"
  mat-menu-item
>
  <mat-icon class="color-warn-i">delete_forever</mat-icon>
  <span class="text">{{T.MH.DELETE_TAG|translate}}</span>
</button>
} @if (isForProject) {
<button
  (click)="deleteProject()"
  mat-menu-item
>
  <mat-icon class="color-warn-i">delete_forever</mat-icon>
  <span class="text">{{ T.MH.DELETE_PROJECT|translate }}</span>
</button>
}
