<ng-container>
  @if (task()?.issueWasUpdated) {
  <div
    @expand
    style="text-align: center"
  >
    <button
      (click)="hideUpdates()"
      color="accent"
      mat-raised-button
    >
      {{T.F.CALDAV.ISSUE_CONTENT.MARK_AS_CHECKED|translate}}
    </button>
  </div>
  }

  <div class="table-wrapper">
    <table class="issue-table">
      <tr>
        <th>{{T.F.CALDAV.ISSUE_CONTENT.SUMMARY|translate}}</th>
        <td><strong>{{issue?.summary}}</strong></td>
      </tr>
      <tr>
        <th>{{T.F.CALDAV.ISSUE_CONTENT.STATUS|translate}}</th>
        <td>{{issue?.completed}}</td>
      </tr>
      @if (issue?.labels?.length) {
      <tr>
        <th>{{T.F.CALDAV.ISSUE_CONTENT.LABELS|translate}}</th>
        <td>
          <mat-chip-listbox>
            @for (label of issue?.labels; track label) {
            <mat-chip-option [title]="label">{{label}} </mat-chip-option>
            }
          </mat-chip-listbox>
        </td>
      </tr>
      } @if (issue?.note) {
      <tr>
        <th>{{T.F.CALDAV.ISSUE_CONTENT.DESCRIPTION|translate}}</th>
        <td class="issue-description">
          <div
            [data]="issue?.note"
            class="description markdown"
            markdown
          ></div>
        </td>
      </tr>
      }
    </table>
  </div>
</ng-container>
