<div class="mac-os-drag-bar"></div>

@if (!(isPomodoroEnabled$|async)) {
<header>
  <banner></banner>
</header>
@if ((activePage$|async); as activePage) {
<main>
  @if (activePage === FocusModePage.ProcrastinationHelp) {
  <button
    class="close-btn"
    mat-icon-button
    (click)="leaveProcrastinationHelp()"
  >
    <mat-icon>close</mat-icon>
  </button>
  } @if (activePage !== FocusModePage.ProcrastinationHelp) {
  <button
    class="close-btn"
    mat-icon-button
    [matTooltip]="T.F.FOCUS_MODE.CANCEL_FOCUS_SESSION|translate"
    (click)="cancelFocusSession()"
  >
    <mat-icon>close</mat-icon>
  </button>
  } @switch (activatePage) { @case (FocusModePage.ProcrastinationHelp) {
  <div
    class="scroll-container"
    @warpIn
  >
    <procrastination></procrastination>
  </div>
  } @case (FocusModePage.TaskSelection) {
  <focus-mode-task-selection @warpIn></focus-mode-task-selection>
  } @case (FocusModePage.DurationSelection) {
  <focus-mode-duration-selection @warpIn></focus-mode-duration-selection>
  } @case (FocusModePage.Preparation) {
  <focus-mode-preparation @warpIn></focus-mode-preparation>
  } @case (FocusModePage.Main) {
  <focus-mode-main @warpIn></focus-mode-main>
  } @case (FocusModePage.SessionDone) {
  <focus-mode-task-done @warpIn></focus-mode-task-done>
  } }
</main>
} } @else {
<button
  class="close-btn"
  mat-icon-button
  [matTooltip]="T.F.FOCUS_MODE.CANCEL_FOCUS_SESSION|translate"
  (click)="cancelFocusSession()"
>
  <mat-icon>close</mat-icon>
</button>
<div class="pomodoro-info-wrapper">
  <div class="pomodoro-info-msg">{{T.F.FOCUS_MODE.POMODORO_INFO|translate}}</div>
  <div style="text-align: center">
    <button
      mat-stroked-button
      color="primary"
      (click)="deactivatePomodoro()"
    >
      <mat-icon>alarm_off</mat-icon>
      {{T.F.FOCUS_MODE.POMODORO_DISABLE|translate}}
    </button>
    <button
      mat-stroked-button
      (click)="cancelFocusSession()"
    >
      <mat-icon>arrow_back</mat-icon>
      {{T.F.FOCUS_MODE.BACK_TO_PLANNING|translate}}
    </button>
  </div>
</div>
}
