<better-simple-drawer
  [isOpen]="isPanelOpen"
  [sideWidth]="300"
  cdkDropListGroup
>
  <ng-container better-drawer-content>
    <planner-plan-view cdkScrollable></planner-plan-view>

    <!--    <div class="controls">-->
    <!--      <mat-button-toggle-group>-->
    <!--        <mat-button-toggle-->
    <!--          value="SPLIT"-->
    <!--          class="hide-xs"-->
    <!--        >-->
    <!--          <mat-icon>vertical_split</mat-icon>-->
    <!--          Plan-->
    <!--        </mat-button-toggle>-->
    <!--        <mat-button-toggle value="PARSED">-->
    <!--          <mat-icon>photo</mat-icon>-->
    <!--          Flow-->
    <!--        </mat-button-toggle>-->
    <!--      </mat-button-toggle-group>-->

    <!--      &lt;!&ndash;      <div class="day-selector">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <button mat-icon-button>&ndash;&gt;-->
    <!--      &lt;!&ndash;          <mat-icon>chevron_left</mat-icon>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </button>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="start-date">27.3</div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <button mat-icon-button>&ndash;&gt;-->
    <!--      &lt;!&ndash;          <mat-icon>chevron_right</mat-icon>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </button>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;  <button mat-icon-button>&ndash;&gt;-->
    <!--      &lt;!&ndash;    <mat-icon>settings</mat-icon>&ndash;&gt;-->
    <!--      &lt;!&ndash;  </button>&ndash;&gt;-->
    <!--    </div>-->
  </ng-container>

  <ng-container better-drawer-side>
    <add-task-panel-planner (closePanel)="isPanelOpen=false"></add-task-panel-planner>
  </ng-container>
</better-simple-drawer>

@if (!isPanelOpen) {
<div class="fab-wrapper FAB-BTN">
  <button
    mat-fab
    class="show-xs-only"
    color="primary"
    (click)="layoutService.showAddTaskBar()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <button
    mat-fab
    color="primary"
    matTooltip="{{T.F.PLANNER.TASK_DRAWER|translate}}"
    (click)="isPanelOpen = true"
  >
    <mat-icon>playlist_add</mat-icon>
  </button>
</div>
}
