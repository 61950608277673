<div class="wrapper">
  @if (isDialogMode||isOver()|| IS_TOUCH_PRIMARY) {
  <header class="task-title-wrapper">
    <inline-multiline-input
      [resetToLastExternalValueTrigger]="task"
      (valueEdited)="updateTaskTitleIfChanged($event.wasChanged, $event.newVal)"
      [value]="task.title"
      class="task-title"
    ></inline-multiline-input>
    <!--    <button mat-icon-button>-->
    <!--      <mat-icon>more_vert</mat-icon>-->
    <!--    </button>-->
  </header>
  } @if (!task.parentId && ((layoutService.isRightPanelOver$|async) || isDialogMode)) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (keyPress)="onItemKeyPress($event)"
    [expanded]="task.subTasks?.length > 0 && !isDialogMode"
    [type]="'panel'"
  >
    <ng-container panel-header>
      <mat-icon class="panel-header-icon">list</mat-icon>
      <span
        >{{T.F.TASK.ADDITIONAL_INFO.SUB_TASKS|translate:{nr: task.subTasks?.length}
        }}</span
      >
    </ng-container>
    <ng-container panel-content>
      @if (task.subTasks && task.subTasks.length) {
      <div class="sub-tasks">
        @if (task.subTasks?.length) {
        <task-list
          [@expand]
          [isHideAll]="(task._showSubTasksMode!==ShowSubTasksMode.Show)"
          [isHideDone]="(task._showSubTasksMode===ShowSubTasksMode.HideDone)"
          [listModelId]="task.id"
          [parentId]="task.id"
          [tasks]="task.subTasks"
          listId="SUB"
        ></task-list>
        }
      </div>
      }
      <div style="text-align: center">
        <button
          (click)="addSubTask()"
          mat-button
        >
          <mat-icon>add</mat-icon>
          <span>{{T.F.TASK.ADDITIONAL_INFO.ADD_SUB_TASK|translate}}</span>
        </button>
      </div>
    </ng-container>
  </task-detail-item>
  } @if (task.parentId) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (editActionTriggered)="taskService.setSelectedIdToParentAndSwitchContextIfNecessary(task)"
    (keyPress)="onItemKeyPress($event)"
    [inputIcon]="'arrow_forward'"
    class="input-item --estimate"
  >
    <ng-container input-title>
      <mat-icon>subdirectory_arrow_right</mat-icon>
      <span>{{T.F.TASK.ADDITIONAL_INFO.PARENT|translate}}</span>
    </ng-container>
    <ng-container input-value>
      @if ((parentTaskData$|async)?.issueType; as issueType) {
      <mat-icon [svgIcon]="issueType|issueIcon"></mat-icon>
      }
      <span>{{(taskService.getByIdOnce$(task.parentId)|async)?.title}}</span>
    </ng-container>
  </task-detail-item>
  } @if (!task.subTasks?.length) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (editActionTriggered)="estimateTime()"
    (keyPress)="onItemKeyPress($event)"
    [inputIcon]="'edit'"
    class="input-item --estimate"
  >
    <ng-container input-title>
      <mat-icon>timer</mat-icon>
      <span>{{T.F.TASK.ADDITIONAL_INFO.TIME|translate}}</span>
    </ng-container>
    <ng-container input-value>
      <span class="time-val">
        <span [innerHTML]="task.timeSpent|msToString"></span>
      </span>
      <span class="separator">/</span>
      <span
        [innerHTML]="task.timeEstimate|msToString"
        class="time-val"
      ></span>
      <ng-container input-additional>
        <progress-bar [progress]="progress"></progress-bar>
      </ng-container>
    </ng-container>
  </task-detail-item>
  } @if ((plannerService.plannedTaskDayMap$|async); as plannedTaskDayMap) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (editActionTriggered)="scheduleTask()"
    (keyPress)="onItemKeyPress($event)"
    [inputIcon]="(task.reminderId|| (plannedTaskDayMap||{})[task.id]) ? 'edit': 'add'"
    class="input-item"
  >
    <ng-container input-title>
      <mat-icon>alarm</mat-icon>
      @if ((task.reminderId|| (plannedTaskDayMap||{})[task.id])) {
      <span>{{ T.F.TASK.ADDITIONAL_INFO.SCHEDULED_AT|translate }}</span>
      } @if (!(task.reminderId|| (plannedTaskDayMap||{})[task.id])) {
      <span>{{ T.F.TASK.ADDITIONAL_INFO.SCHEDULE_TASK|translate }}</span>
      }
    </ng-container>
    <ng-container input-value>
      @if (reminderData$|async; as reminderData) {
      <div
        [matTooltip]="reminderData.remindAt|date:'short'"
        class="reminder-value"
        matTooltipPosition="left"
      >
        {{ reminderData.remindAt|date:'short' }}
      </div>
      } @if ((plannedTaskDayMap||{})[task.id]) {
      <div class="reminder-value">
        {{ (plannedTaskDayMap || {})[task.id]|date:'shortDate' }}
      </div>
      }
    </ng-container>
  </task-detail-item>
  } @if (task.issueId && task.issueType !==ICAL_TYPE) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (keyPress)="onItemKeyPress($event)"
    [expanded]="isExpandedIssuePanel && !isDialogMode"
    [type]="'panel'"
  >
    <ng-container panel-header>
      <issue-header [task]="task"></issue-header>
    </ng-container>
    <ng-container panel-content>
      @if (!(isIssueDataLoadedForCurrentType$|async)) {
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
      } @if (issueData && (isIssueDataLoadedForCurrentType$|async)) {
      <issue-content
        [@expandFadeInOnly]
        [issueData]="issueData"
        [task]="task"
        class="panel-content-wrapper issue-content-wrapper"
      ></issue-content>
      }
    </ng-container>
  </task-detail-item>
  }

  <task-detail-item
    #noteWrapperElRef
    (collapseParent)="collapseParent()"
    (editActionTriggered)="isFocusNotes=true"
    (keyPress)="onItemKeyPress($event)"
    [expanded]="isExpandedNotesPanel && !isDialogMode"
    [type]="'panel'"
  >
    <ng-container panel-header>
      @if(isMarkdownChecklist){
      <mat-icon>checklist</mat-icon>
      Checklist
      <!-- -->
      } @else { @if (task.notes) {
      <mat-icon>chat</mat-icon>
      } @if (!task.notes) {
      <mat-icon>chat_bubble_outline</mat-icon>
      }
      <span>{{T.F.TASK.ADDITIONAL_INFO.NOTES|translate}}</span>
      }
    </ng-container>

    <ng-container panel-content>
      <inline-markdown
        (blur)="isFocusNotes=false"
        (blurred)="isFocusNotes=false"
        (changed)="changeTaskNotes($event); isFocusNotes=false"
        (keyboardUnToggle)="focusItem(noteWrapperElRef)"
        [isFocus]="isFocusNotes"
        [isShowControls]="true"
        [isShowChecklistToggle]="true"
        [isDefaultText]="!task.notes"
        [model]="task.notes|| defaultTaskNotes"
      ></inline-markdown>
    </ng-container>
  </task-detail-item>

  @if (issueAttachments?.length || localAttachments?.length) {
  <task-detail-item
    #attachmentPanelElRef
    (collapseParent)="collapseParent()"
    (keyPress)="onItemKeyPress($event)"
    [expanded]="isExpandedAttachmentPanel && !isDialogMode"
    [type]="'panel'"
  >
    <ng-container panel-header>
      <mat-icon>attachment</mat-icon>
      <span
        >{{T.F.TASK.ADDITIONAL_INFO.ATTACHMENTS|translate:{ nr: issueAttachments?.length +
        localAttachments?.length } }}</span
      >
    </ng-container>
    <ng-container panel-content>
      <div
        class="panel-content-wrapper attachment-list-panel-content-wrapper"
        style="padding-bottom: 8px"
      >
        @if (task.issueType) {
        <task-attachment-list
          [attachments]="issueAttachments"
          [isDisableControls]="true"
          [taskId]="task.id"
        ></task-attachment-list>
        } @if (issueAttachments?.length && localAttachments?.length) {
        <h3
          class="mat-h3"
          style="margin-bottom: 0"
        >
          {{T.F.TASK.ADDITIONAL_INFO.LOCAL_ATTACHMENTS|translate}}
        </h3>
        }
        <task-attachment-list
          [attachments]="localAttachments"
          [taskId]="task.id"
        ></task-attachment-list>
        <div style="text-align: center">
          <button
            (click)="addAttachment()"
            mat-button
          >
            <mat-icon>add</mat-icon>
            <span>{{T.F.TASK.ADDITIONAL_INFO.ADD_ATTACHMENT|translate}}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </task-detail-item>
  } @if (!issueAttachments?.length && !localAttachments?.length) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (editActionTriggered)="addAttachment()"
    (keyPress)="onItemKeyPress($event)"
    [inputIcon]="'add'"
    class="input-item"
  >
    <ng-container input-title>
      <mat-icon>attachment</mat-icon>
      <span>{{T.F.TASK.ADDITIONAL_INFO.ADD_ATTACHMENT|translate}}</span>
    </ng-container>
    <ng-container input-value></ng-container>
  </task-detail-item>
  } @if (!task.parentId && !task.issueId) {
  <task-detail-item
    (collapseParent)="collapseParent()"
    (editActionTriggered)="editTaskRepeatCfg()"
    (keyPress)="onItemKeyPress($event)"
    [inputIcon]="task.repeatCfgId ? 'edit': 'add'"
    class="input-item"
  >
    <ng-container input-title>
      <mat-icon svgIcon="repeat"></mat-icon>
      <span>{{T.F.TASK.ADDITIONAL_INFO.REPEAT|translate}}</span>
    </ng-container>
    <ng-container input-value>
      @if (repeatCfgLabel$|async; as repeatCfgLabel) {
      <div
        class="reminder-value"
        matTooltipPosition="left"
      >
        {{repeatCfgLabel}}
      </div>
      }
    </ng-container>
  </task-detail-item>
  }

  <task-detail-item
    #tagEditDetailItemElRef
    (collapseParent)="collapseParent()"
    (keyPress)="onItemKeyPress($event)"
    (editActionTriggered)="tagEditElRef.focusInput()"
    type="fullSizeInput"
    class="input-item --estimate"
  >
    <ng-container input-title>
      <mat-icon>style</mat-icon>
    </ng-container>
    <ng-container input-value>
      <tag-edit
        #tagEditElRef
        [task]="task"
        [tagIds]="task.tagIds"
        (escapePress)="tagEditDetailItemElRef.focusEl()"
      ></tag-edit>
    </ng-container>
  </task-detail-item>

  <div class="date-info">
    <div>Created on {{task?.created|date:'short'}}</div>
    @if (task.doneOn) {
    <div>Completed on {{task?.doneOn|date:'short'}}</div>
    }
  </div>
</div>

@if (isDragOver) {
<div class="bgc-accent drag-over-msg">
  <mat-icon>add</mat-icon>
  {{T.F.TASK.CMP.DROP_ATTACHMENT|translate:{title: task?.title} }}
</div>
}
