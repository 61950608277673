@for (project of (projects$|async) || []; track project.id) {
<section class="project-section">
  @if (project.id !== (workContextService.activeWorkContextId$|async)) {
  <div
    [style.border-color]="project.color"
    class="project-title"
  >
    <h3>{{project.title}}</h3>
    <mat-icon>timer</mat-icon>
    @if (!isShowYesterday()) {
    <div>{{project.timeSpentToday|msToString}}</div>
    } @if (isShowYesterday()) {
    <div>
      {{project.timeSpentToday > 0 ? (project.timeSpentToday|msToString) : ''}}
      <!---->
      @if (project.timeSpentYesterday && project.timeSpentYesterday >0) {
      ({{project.timeSpentYesterday|msToString}} {{T.G.YESTERDAY|translate}}) }
    </div>
    }
  </div>
  }
  <task-summary-table
    (updated)="onTaskSummaryEdit()"
    [day]="dayStr()"
    [flatTasks]="project.tasks"
  ></task-summary-table>
  <div style="text-align: center; margin-top: 8px; margin-bottom: 16px">
    <button
      [matMenuTriggerFor]="roundTimeActions"
      [title]="T.PDS.ROUND_TIME_SPENT_TITLE|translate"
      mat-button
    >
      <mat-icon>restore</mat-icon>
      {{T.PDS.ROUND_TIME_SPENT|translate}}
    </button>
    @if (isForToday()) {
    <button
      (click)="showExportModal(project.id)"
      mat-button
    >
      <mat-icon>call_made</mat-icon>
      {{T.PDS.EXPORT_TASK_LIST|translate}}
    </button>
    }
    <mat-menu #roundTimeActions="matMenu">
      <ng-template matMenuContent>
        <button
          mat-menu-item
          style="color: red"
        >
          {{T.PDS.ROUND_TIME_WARNING|translate}}
        </button>
        <button
          (click)="roundTimeForTasks(project.id,'5M')"
          mat-menu-item
        >
          {{T.PDS.ROUND_5M|translate}}
        </button>
        <button
          (click)="roundTimeForTasks(project.id,'5M', true)"
          mat-menu-item
        >
          {{T.PDS.ROUND_UP_5M|translate}}
        </button>
        <button
          (click)="roundTimeForTasks(project.id,'QUARTER')"
          mat-menu-item
        >
          {{T.PDS.ROUND_15M|translate}}
        </button>
        <button
          (click)="roundTimeForTasks(project.id,'QUARTER', true)"
          mat-menu-item
        >
          {{T.PDS.ROUND_UP_15M|translate}}
        </button>
        <button
          (click)="roundTimeForTasks(project.id,'HALF')"
          mat-menu-item
        >
          {{T.PDS.ROUND_30M|translate}}
        </button>
        <button
          (click)="roundTimeForTasks(project.id,'HALF', true)"
          mat-menu-item
        >
          {{T.PDS.ROUND_UP_30M|translate}}
        </button>
      </ng-template>
    </mat-menu>
  </div>
</section>
}
