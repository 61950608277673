<div
  #contentElRef
  class="content"
>
  <ng-content select="[better-drawer-content]"></ng-content>
</div>

@if (isOpenGet && isOverGet) {
<div
  (click)="close()"
  @fade
  class="backdrop"
></div>
}

<div
  (swiperight)="IS_TOUCH_PRIMARY && close()"
  [style]="sideStyle"
  class="side"
  (transitionend)="updateStyleAfterTransition()"
>
  <button
    (click)="close()"
    class="close-btn"
  >
    &times;
  </button>
  <div class="side-inner">
    <ng-content select="[better-drawer-side]"></ng-content>
  </div>
</div>
