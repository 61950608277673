@if ((isOnline$|async)) { @if (task()?.issueWasUpdated) {
<mat-icon
  color="accent"
  style="margin-right: 8px"
  >update
</mat-icon>
} @if (!task()?.issueWasUpdated) {
<mat-icon
  style="margin-right: 8px"
  svgIcon="jira"
>
</mat-icon>
} } @else {
<mat-icon
  [matTooltip]="'No internet!'"
  style="margin-right: 8px"
  >cloud_off
</mat-icon>
}
<span class="tab-label-text">Jira</span>
