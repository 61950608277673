<div class="page-wrapper">
  @if ( (taskRepeatCfgs$|async); as taskRepeatCfgs) {
  <section
    class="component-wrapper"
    style="margin-top: 24px"
  >
    <h2>{{ T.SCHEDULE.REPEATED_TASKS|translate }}</h2>
    @if (!taskRepeatCfgs?.length) {
    <p class="no-scheduled-tasks">{{ T.SCHEDULE.NO_REPEATABLE_TASKS|translate }}</p>
    }
    <div
      [@standardList]="taskRepeatCfgs?.length"
      class="repeat-task-cfgs"
    >
      @for (taskRepeatCfg of taskRepeatCfgs; track taskRepeatCfg.id) {
      <mat-card
        appearance="outlined"
        class="repeat-task-cfg"
      >
        <mat-card-content>
          <div class="repeat-task-cfg-content">
            <div class="task-info">
              <div class="title">
                <inline-multiline-input
                  (valueEdited)="updateRepeatableTitleIfChanged($event.wasChanged, $event.newVal, taskRepeatCfg)"
                  [value]="taskRepeatCfg.title"
                  class="task-title"
                ></inline-multiline-input>
                <tag-list
                  [isShowProjectTagAlways]="true"
                  [task]="taskRepeatCfg"
                ></tag-list>
              </div>
              <div
                (click)="editTaskRepeatCfg(taskRepeatCfg)"
                class="repeat-text-and-icon"
                mat-ripple
              >
                <div class="repeat-text">{{ getRepeatInfoText(taskRepeatCfg) }}</div>
                <mat-icon svgIcon="repeat"></mat-icon>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      }
    </div>
  </section>
  } @else {
  <full-page-spinner></full-page-spinner>
  } @if ( (scheduledTaskService.allScheduledTasks$|async); as allScheduledTasks) {
  <section
    class="component-wrapper"
    style="margin-top: 24px"
  >
    <h2>{{ T.SCHEDULE.SCHEDULED_TASKS_WITH_REMINDER|translate }}</h2>
    @if (!allScheduledTasks?.length) {
    <p class="no-scheduled-tasks">{{ T.SCHEDULE.NO_SCHEDULED|translate }}</p>
    }
    <div
      [@standardList]="allScheduledTasks?.length"
      class="tasks"
    >
      @for (task of allScheduledTasks; track task.id) {
      <planner-task [task]="task">
        <div
          (click)="editReminder(task, $event)"
          class="due-date"
          mat-ripple
        >
          <div class="date-and-time-left">
            <div class="date hide-xs">
              {{ task.plannedAt|date:'EE, d MMM, ' }} {{ task.plannedAt|date:'shortTime'
              }}
            </div>
            <div class="date show-xs-only">
              {{ task.plannedAt|date:'d MMM, ' }}, {{ task.plannedAt|date:'shortTime' }}
            </div>
            <div class="time-left">({{ task.plannedAt|humanizeTimestamp }})</div>
          </div>
          <mat-icon>alarm</mat-icon>
        </div>
      </planner-task>
      }
    </div>
  </section>
  } @else {
  <full-page-spinner></full-page-spinner>
  } @if ((tasksPlannedForDays$|async); as allScheduledTasks) {
  <section
    class="component-wrapper"
    style="margin-top: 24px"
  >
    <h2>{{ T.SCHEDULE.NO_SCHEDULED_TITLE|translate }}</h2>
    @if (!allScheduledTasks?.length) {
    <p class="no-scheduled-tasks">{{ T.SCHEDULE.NO_SCHEDULED|translate }}</p>
    }
    <div
      [@standardList]="allScheduledTasks?.length"
      class="tasks"
    >
      @for (task of allScheduledTasks; track task.id) {
      <planner-task [task]="task">
        <div
          (click)="editReminder(task, $event)"
          class="due-date"
          mat-ripple
        >
          <div class="date-and-time-left">
            <div class="date">{{ task.plannedDay|date:'EE, d MMM' }}</div>
            <div class="time-left">({{ task.plannedDay|humanizeTimestamp }})</div>
          </div>
          <mat-icon>alarm</mat-icon>
        </div>
      </planner-task>
      }
    </div>
  </section>
  } @else {
  <full-page-spinner></full-page-spinner>
  }
</div>
