<!-- TODO make this work -->
<!--<mat-icon matSuffix="">{{formControl.value}}</mat-icon>-->

<mat-icon>{{ formControl.value }}</mat-icon>

<input
  [ngModel]="formControl.value"
  (ngModelChange)="onInputValueChange($event)"
  [formlyAttributes]="field"
  [matAutocomplete]="auto"
  matInput
  type="text"
/>
<!--<span matPrefix>+1 &nbsp;</span>-->

<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="onIconSelect($event.option.value)"
  [autoActiveFirstOption]="false"
>
  @for (icon of (filteredIcons); track trackByIndex($index, icon)) {
  <mat-option [value]="icon">
    <mat-icon>{{icon}}</mat-icon>
    <span>{{icon}}</span>
  </mat-option>
  }
</mat-autocomplete>

@if(IS_ELECTRON && !isLinux){
<mat-icon
  matSuffix
  type="button"
  [matTooltip]="'Open system emoji picker if any'"
  (click)="openEmojiPicker()"
  >add_reaction</mat-icon
>
}
