<ng-container>
  <h3>
    <a
      [href]="issue?.url"
      target="_blank"
    >
      {{issue?.tracker.name}} #{{issue?.id}}
    </a>
  </h3>

  <div class="table-wrapper">
    <table class="issue-table">
      <tr>
        <th>{{T.F.REDMINE.ISSUE_CONTENT.AUTHOR|translate}}</th>
        <td>{{issue?.author.name}}</td>
      </tr>

      <tr>
        <th>{{T.F.REDMINE.ISSUE_CONTENT.STATUS|translate}}</th>
        <td>{{issue?.status.name}}</td>
      </tr>

      <tr>
        <th>{{T.F.REDMINE.ISSUE_CONTENT.PRIORITY|translate}}</th>
        <td>{{issue?.priority.name}}</td>
      </tr>

      @if (issue?.description) {
      <tr>
        <th>{{T.F.REDMINE.ISSUE_CONTENT.DESCRIPTION|translate}}</th>
        <td>{{issue?.description}}</td>
      </tr>
      }
    </table>
  </div>

  @if (task()?.issueWasUpdated) {
  <div
    @expand
    style="text-align: center"
  >
    <button
      (click)="hideUpdates()"
      color="accent"
      mat-raised-button
    >
      {{T.F.REDMINE.ISSUE_CONTENT.MARK_AS_CHECKED|translate}}
    </button>
  </div>
  }
</ng-container>
