<div
  (click)="focusInput()"
  class="inline-input-wrapper"
>
  <div class="value-wrapper">{{displayValue() || value()}}</div>

  @if (type==='duration') {
  <input
    #inputElDuration
    spellcheck="false"
    (blur)="blur()"
    (keypress)="keypressHandler($event)"
    (ngModelChange)="onChange($event)"
    [ngModel]="value()"
    class="duration-input"
    inputDuration
  />
  } @if (type!=='duration') {
  <input
    #inputEl
    (blur)="blur()"
    (keypress)="keypressHandler($event)"
    (ngModelChange)="onChange($event)"
    [ngModel]="value()"
    [type]="type"
  />
  }
</div>
