@if (bookmarkCopy) {
<form
  #form="ngForm"
  (submit)="$event.preventDefault();submit()"
>
  <h1 mat-dialog-title>
    {{ (bookmarkCopy.id ? (T.F.BOOKMARK.DIALOG_EDIT.EDIT_BOOKMARK) :
    (T.F.BOOKMARK.DIALOG_EDIT.ADD_BOOKMARK)) |translate }}
  </h1>
  <mat-dialog-content>
    <div class="form-wrapper">
      <mat-form-field>
        <mat-label>{{(T.G.TITLE|translate)}}</mat-label>
        <input
          [(ngModel)]="bookmarkCopy.title"
          autofocus="autofocus"
          matInput
          name="title"
          type="text"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{mapTypeToLabel(bookmarkCopy.type)|translate}}</mat-label>
        <input
          [(ngModel)]="bookmarkCopy.path"
          matInput
          name="path"
          required
          type="text"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{(T.F.BOOKMARK.DIALOG_EDIT.SELECT_TYPE|translate)}}</mat-label>
        <mat-select
          [(ngModel)]="bookmarkCopy.type"
          name="type"
          required="true"
        >
          @for (type of types; track trackByIndex($index, type)) {
          <mat-option [value]="type.type">{{ (type.title|translate) }} </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div class="custom-icon-wrapper">
        <mat-form-field>
          <mat-label>{{(T.F.BOOKMARK.DIALOG_EDIT.SELECT_ICON|translate)}}</mat-label>
          <input
            [(ngModel)]="bookmarkCopy.icon"
            [formControl]="iconControl"
            [matAutocomplete]="auto"
            matInput
            type="text"
          />
          <mat-icon matPrefix="">{{bookmarkCopy.icon}}</mat-icon>
          <mat-autocomplete
            #auto="matAutocomplete"
            [autoActiveFirstOption]="true"
          >
            @for (icon of (filteredIcons$|async); track trackByIndex($index, icon)) {
            <mat-option [value]="icon">
              <mat-icon>{{icon}}</mat-icon>
              <span>{{icon}}</span>
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      (click)="close()"
      color="primary"
      mat-button
      type="button"
    >
      {{T.G.CANCEL|translate}}
    </button>
    <button
      [disabled]="!form.valid"
      color="primary"
      mat-stroked-button
      type="submit"
    >
      <mat-icon>save</mat-icon>
      {{T.G.SAVE|translate}}
    </button>
  </mat-dialog-actions>
</form>
}
