<header>
  <button
    #buttonEl
    (click)="addNote()"
    id="add-note-btn"
    mat-button
  >
    <mat-icon>add</mat-icon>
    {{ T.F.NOTE.NOTES_CMP.ADD_BTN|translate }}
  </button>
</header>

@if (isDragOver) {
<div
  @fade
  class="bgc-accent drag-over-msg"
>
  <mat-icon>add</mat-icon>
  {{ T.F.NOTE.NOTES_CMP.DROP_TO_ADD|translate }}
</div>
}

<!---->
@let notes = workContextService.notes$|async;
<div
  [@standardList]="notes?.length"
  cdkDropList
  [cdkDropListData]="notes"
  (cdkDropListDropped)="drop($event)"
  class="notes"
>
  <!---->
  @for( note of notes; track note.id) {
  <div cdkDrag>
    <note
      [isFocus]="isElementWasAdded && $index===0"
      [note]="note"
    ></note>
  </div>
  }
  <!---->
  @if(notes?.length === 0) {
  <div class="no-notes-info">{{ T.F.NOTE.NOTES_CMP.NO_NOTES|translate }}</div>
  }
</div>
