@if (note) {
<div
  [class.isFocused]="isFocus()"
  [class.isImg]="note.imgUrl"
  class="note"
>
  @if (note.imgUrl) {
  <img
    [enlargeImg]="note.imgUrl"
    [src]="note.imgUrl"
    (longPress)="editFullscreen($event)"
    class="note-img"
  />
  } @if (!note.imgUrl) {
  <div class="content">
    <div
      (click)="editFullscreen($event)"
      markdown
      [data]="isLongNote ? shortenedNote : note.content"
      class="markdown-preview markdown"
    ></div>
  </div>
  }
  <div
    class="bottom-controls handle-drag"
    [class.isOver]="note.imgUrl"
  >
    @if (note.projectId) {
    <button
      mat-icon-button
      (click)="togglePinToToday()"
    >
      @if (!note.isPinnedToToday) {
      <mat-icon>wb_sunny</mat-icon>
      } @if (note.isPinnedToToday) {
      <mat-icon svgIcon="remove_today"></mat-icon>
      }
    </button>
    } @if (projectTag$|async; as projectTag) {
    <tag [tag]="projectTag"></tag>
    }
    <!--    <button mat-icon-button>-->
    <!--      <mat-icon class="drag-handle-ico" svgIcon="drag_handle"></mat-icon>-->
    <!--    </button>-->
    <button
      [matMenuTriggerFor]="menu"
      mat-icon-button
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>
}

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button
      [matMenuTriggerFor]="projectMenu"
      mat-menu-item
    >
      <mat-icon>forward</mat-icon>
      {{(note.projectId ? T.F.TASK.CMP.MOVE_TO_OTHER_PROJECT :
      T.F.TASK.CMP.ADD_TO_PROJECT)|translate}}
    </button>

    @if (!note.imgUrl) {
    <button
      (click)="toggleLock()"
      mat-menu-item
    >
      @if (note.isLock) {
      <mat-icon>lock</mat-icon>
      } @if (!note.isLock) {
      <mat-icon>lock_open</mat-icon>
      } {{note.isLock ? (T.F.NOTE.NOTE_CMP.ENABLE_PARSE|translate) :
      (T.F.NOTE.NOTE_CMP.DISABLE_PARSE|translate)}}
    </button>
    }

    <button
      (click)="removeNote()"
      mat-menu-item
    >
      <mat-icon style="color: #e15d63">delete_forever</mat-icon>
      {{T.G.DELETE|translate}}
    </button>

    <mat-menu #projectMenu="matMenu">
      <ng-template matMenuContent>
        @for (project of (moveToProjectList$|async); track trackByProjectId($index,
        project)) {
        <button
          (click)="moveNoteToProject(project.id)"
          mat-menu-item
        >
          {{project.title}}
        </button>
        }
      </ng-template>
    </mat-menu>
  </ng-template>
</mat-menu>
