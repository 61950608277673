<mat-dialog-content>
  <div style="text-align: center; margin-bottom: 16px">
    <mat-icon
      color="warn"
      style="font-size: 48px; height: 48px; width: 48px"
      >error
    </mat-icon>
  </div>

  <p><strong> {{ T.F.SYNC.D_INCOMPLETE_SYNC.T1|translate }} </strong></p>

  <p>
    {{ T.F.SYNC.D_INCOMPLETE_SYNC.T2|translate }}
    <code
      >mfRev: {{ data?.archiveRevInMainFile }} & realRev: {{ data?.archiveRevReal }}</code
    >
  </p>
  <p>{{ T.F.SYNC.D_INCOMPLETE_SYNC.T3|translate }}</p>

  <p>{{ T.F.SYNC.D_INCOMPLETE_SYNC.T4|translate }}</p>
  <p>{{ T.F.SYNC.D_INCOMPLETE_SYNC.T5|translate }}</p>

  <p style="text-align: center; margin-top: 16px">
    <strong> {{ T.F.SYNC.D_INCOMPLETE_SYNC.T6|translate }} </strong>
  </p>

  <div style="text-align: center; margin-bottom: 16px">
    <button
      (click)="downloadBackup()"
      color="primary"
      mat-stroked-button
      type="button"
    >
      <mat-icon>file_download</mat-icon>
      {{ T.F.SYNC.D_INCOMPLETE_SYNC.BTN_DOWNLOAD_BACKUP|translate }}
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button
      (click)="close()"
      color="primary"
      mat-stroked-button
    >
      {{ T.G.CANCEL|translate }}
    </button>
    @if (!IS_ANDROID_WEB_VIEW) {
    <button
      (click)="closeApp()"
      color="primary"
      mat-stroked-button
    >
      <mat-icon>power_settings_new</mat-icon>
      {{ T.F.SYNC.D_INCOMPLETE_SYNC.BTN_CLOSE_APP|translate }}
    </button>
    }

    <button
      (click)="close('FORCE_UPDATE_REMOTE')"
      color="warn"
      mat-stroked-button
    >
      <mat-icon>file_upload</mat-icon>
      {{ T.F.SYNC.D_INCOMPLETE_SYNC.BTN_FORCE_UPLOAD|translate }}
    </button>
  </div>
</mat-dialog-actions>
