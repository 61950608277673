<div class="circle bg-primary">
  <div class="inner-circle"></div>
  <div class="dots">
    @for (hour of dots; track trackByIndex($index, hour)) {
    <div
      @dot
      class="dot bg-100"
    ></div>
    }
  </div>
  <div
    #circleEl
    class="handle-wrapper"
  >
    <div class="handle"></div>
  </div>
  <div class="value-wrapper">
    @if (label) {
    <label
      [for]="uid"
      [innerHtml]="label"
    ></label>
    }
    <input
      (ngModelChange)="onInputChange($event)"
      [id]="uid"
      [ngModel]="_model"
      [placeholder]="T.G.EXAMPLE_VAL|translate"
      class="value"
      inputDuration="optional"
      spellcheck="false"
      type="text"
    />
  </div>
</div>
