const T = {
  ANDROID: {
    NOTIFICATIONS: {
      SYNC_CONFLICT_MSG: 'ANDROID.NOTIFICATIONS.SYNC_CONFLICT_MSG',
      SYNC_CONFLICT_TITLE: 'ANDROID.NOTIFICATIONS.SYNC_CONFLICT_TITLE',
    },
    PERMANENT_NOTIFICATION_MSGS: {
      INITIAL: 'ANDROID.PERMANENT_NOTIFICATION_MSGS.INITIAL',
      NO_ACTIVE_TASKS: 'ANDROID.PERMANENT_NOTIFICATION_MSGS.NO_ACTIVE_TASKS',
      SYNCING: 'ANDROID.PERMANENT_NOTIFICATION_MSGS.SYNCING',
    },
  },
  APP: {
    B_INSTALL: {
      IGNORE: 'APP.B_INSTALL.IGNORE',
      INSTALL: 'APP.B_INSTALL.INSTALL',
      MSG: 'APP.B_INSTALL.MSG',
    },
    B_OFFLINE: 'APP.B_OFFLINE',
    UPDATE_MAIN_MODEL: 'APP.UPDATE_MAIN_MODEL',
    UPDATE_MAIN_MODEL_NO_UPDATE: 'APP.UPDATE_MAIN_MODEL_NO_UPDATE',
    UPDATE_WEB_APP: 'APP.UPDATE_WEB_APP',
  },
  BL: {
    NO_TASKS: 'BL.NO_TASKS',
  },
  CONFIRM: {
    AUTO_FIX: 'CONFIRM.AUTO_FIX',
    DELETE_STRAY_BACKUP: 'CONFIRM.DELETE_STRAY_BACKUP',
    RELOAD_AFTER_IDB_ERROR: 'CONFIRM.RELOAD_AFTER_IDB_ERROR',
    RESTORE_FILE_BACKUP: 'CONFIRM.RESTORE_FILE_BACKUP',
    RESTORE_FILE_BACKUP_ANDROID: 'CONFIRM.RESTORE_FILE_BACKUP_ANDROID',
    RESTORE_STRAY_BACKUP: 'CONFIRM.RESTORE_STRAY_BACKUP',
  },
  DATETIME_INPUT: {
    IN: 'DATETIME_INPUT.IN',
    TOMORROW: 'DATETIME_INPUT.TOMORROW',
  },
  DATETIME_SCHEDULE: {
    LATER_TODAY: 'DATETIME_SCHEDULE.LATER_TODAY',
    NEXT_WEEK: 'DATETIME_SCHEDULE.NEXT_WEEK',
    PLACEHOLDER: 'DATETIME_SCHEDULE.PLACEHOLDER',
    PRESS_ENTER_AGAIN: 'DATETIME_SCHEDULE.PRESS_ENTER_AGAIN',
    TOMORROW: 'DATETIME_SCHEDULE.TOMORROW',
  },
  F: {
    ATTACHMENT: {
      DIALOG_EDIT: {
        ADD_ATTACHMENT: 'F.ATTACHMENT.DIALOG_EDIT.ADD_ATTACHMENT',
        EDIT_ATTACHMENT: 'F.ATTACHMENT.DIALOG_EDIT.EDIT_ATTACHMENT',
        LABELS: {
          FILE: 'F.ATTACHMENT.DIALOG_EDIT.LABELS.FILE',
          IMG: 'F.ATTACHMENT.DIALOG_EDIT.LABELS.IMG',
          LINK: 'F.ATTACHMENT.DIALOG_EDIT.LABELS.LINK',
        },
        SELECT_TYPE: 'F.ATTACHMENT.DIALOG_EDIT.SELECT_TYPE',
        TYPES: {
          FILE: 'F.ATTACHMENT.DIALOG_EDIT.TYPES.FILE',
          IMG: 'F.ATTACHMENT.DIALOG_EDIT.TYPES.IMG',
          LINK: 'F.ATTACHMENT.DIALOG_EDIT.TYPES.LINK',
        },
      },
    },
    BOOKMARK: {
      BAR: {
        ADD: 'F.BOOKMARK.BAR.ADD',
        DROP: 'F.BOOKMARK.BAR.DROP',
        EDIT: 'F.BOOKMARK.BAR.EDIT',
        NO_BOOKMARKS: 'F.BOOKMARK.BAR.NO_BOOKMARKS',
      },
      DIALOG_EDIT: {
        ADD_BOOKMARK: 'F.BOOKMARK.DIALOG_EDIT.ADD_BOOKMARK',
        EDIT_BOOKMARK: 'F.BOOKMARK.DIALOG_EDIT.EDIT_BOOKMARK',
        LABELS: {
          COMMAND: 'F.BOOKMARK.DIALOG_EDIT.LABELS.COMMAND',
          FILE: 'F.BOOKMARK.DIALOG_EDIT.LABELS.FILE',
          IMG: 'F.BOOKMARK.DIALOG_EDIT.LABELS.IMG',
          LINK: 'F.BOOKMARK.DIALOG_EDIT.LABELS.LINK',
        },
        SELECT_ICON: 'F.BOOKMARK.DIALOG_EDIT.SELECT_ICON',
        SELECT_TYPE: 'F.BOOKMARK.DIALOG_EDIT.SELECT_TYPE',
        TYPES: {
          COMMAND: 'F.BOOKMARK.DIALOG_EDIT.TYPES.COMMAND',
          FILE: 'F.BOOKMARK.DIALOG_EDIT.TYPES.FILE',
          IMG: 'F.BOOKMARK.DIALOG_EDIT.TYPES.IMG',
          LINK: 'F.BOOKMARK.DIALOG_EDIT.TYPES.LINK',
        },
      },
    },
    CALDAV: {
      DIALOG_INITIAL: {
        TITLE: 'F.CALDAV.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        CALDAV_CATEGORY_FILTER: 'F.CALDAV.FORM.CALDAV_CATEGORY_FILTER',
        CALDAV_PASSWORD: 'F.CALDAV.FORM.CALDAV_PASSWORD',
        CALDAV_RESOURCE: 'F.CALDAV.FORM.CALDAV_RESOURCE',
        CALDAV_URL: 'F.CALDAV.FORM.CALDAV_URL',
        CALDAV_USER: 'F.CALDAV.FORM.CALDAV_USER',
        IS_TRANSITION_ISSUES_ENABLED: 'F.CALDAV.FORM.IS_TRANSITION_ISSUES_ENABLED',
      },
      FORM_SECTION: {
        HELP: 'F.CALDAV.FORM_SECTION.HELP',
        TITLE: 'F.CALDAV.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        DESCRIPTION: 'F.CALDAV.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.CALDAV.ISSUE_CONTENT.LABELS',
        MARK_AS_CHECKED: 'F.CALDAV.ISSUE_CONTENT.MARK_AS_CHECKED',
        STATUS: 'F.CALDAV.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.CALDAV.ISSUE_CONTENT.SUMMARY',
      },
      S: {
        CALENDAR_NOT_FOUND: 'F.CALDAV.S.CALENDAR_NOT_FOUND',
        CALENDAR_READ_ONLY: 'F.CALDAV.S.CALENDAR_READ_ONLY',
        ISSUE_NOT_FOUND: 'F.CALDAV.S.ISSUE_NOT_FOUND',
      },
    },
    CALENDARS: {
      BANNER: {
        ADD_AS_TASK: 'F.CALENDARS.BANNER.ADD_AS_TASK',
        FOCUS_TASK: 'F.CALENDARS.BANNER.FOCUS_TASK',
        TXT: 'F.CALENDARS.BANNER.TXT',
        TXT_MULTIPLE: 'F.CALENDARS.BANNER.TXT_MULTIPLE',
        TXT_PAST: 'F.CALENDARS.BANNER.TXT_PAST',
        TXT_PAST_MULTIPLE: 'F.CALENDARS.BANNER.TXT_PAST_MULTIPLE',
      },
      S: {
        CAL_PROVIDER_ERROR: 'F.CALENDARS.S.CAL_PROVIDER_ERROR',
      },
    },
    CONFIG: {
      S: {
        UPDATE_SECTION: 'F.CONFIG.S.UPDATE_SECTION',
      },
    },
    DOMINA_MODE: {
      FORM: {
        HELP: 'F.DOMINA_MODE.FORM.HELP',
        L_INTERVAL: 'F.DOMINA_MODE.FORM.L_INTERVAL',
        L_TEXT: 'F.DOMINA_MODE.FORM.L_TEXT',
        L_TEXT_DESCRIPTION: 'F.DOMINA_MODE.FORM.L_TEXT_DESCRIPTION',
        TITLE: 'F.DOMINA_MODE.FORM.TITLE',
      },
    },
    DROPBOX: {
      S: {
        ACCESS_TOKEN_ERROR: 'F.DROPBOX.S.ACCESS_TOKEN_ERROR',
        ACCESS_TOKEN_GENERATED: 'F.DROPBOX.S.ACCESS_TOKEN_GENERATED',
        AUTH_ERROR: 'F.DROPBOX.S.AUTH_ERROR',
        AUTH_ERROR_ACTION: 'F.DROPBOX.S.AUTH_ERROR_ACTION',
        OFFLINE: 'F.DROPBOX.S.OFFLINE',
        SYNC_ERROR: 'F.DROPBOX.S.SYNC_ERROR',
        UNABLE_TO_GENERATE_PKCE_CHALLENGE:
          'F.DROPBOX.S.UNABLE_TO_GENERATE_PKCE_CHALLENGE',
      },
    },
    FINISH_DAY_BEFORE_EXIT: {
      C: {
        FINISH_DAY_BEFORE_EXIT: 'F.FINISH_DAY_BEFORE_EXIT.C.FINISH_DAY_BEFORE_EXIT',
      },
    },
    FOCUS_MODE: {
      BACK_TO_PLANNING: 'F.FOCUS_MODE.BACK_TO_PLANNING',
      CANCEL_FOCUS_SESSION: 'F.FOCUS_MODE.CANCEL_FOCUS_SESSION',
      CONGRATS: 'F.FOCUS_MODE.CONGRATS',
      CONTINUE_FOCUS_SESSION: 'F.FOCUS_MODE.CONTINUE_FOCUS_SESSION',
      FINISH_TASK_AND_SELECT_NEXT: 'F.FOCUS_MODE.FINISH_TASK_AND_SELECT_NEXT',
      GET_READY: 'F.FOCUS_MODE.GET_READY',
      GO_TO_PROCRASTINATION: 'F.FOCUS_MODE.GO_TO_PROCRASTINATION',
      GOGOGO: 'F.FOCUS_MODE.GOGOGO',
      NEXT: 'F.FOCUS_MODE.NEXT',
      ON: 'F.FOCUS_MODE.ON',
      OPEN_ISSUE_IN_BROWSER: 'F.FOCUS_MODE.OPEN_ISSUE_IN_BROWSER',
      POMODORO_BACK: 'F.FOCUS_MODE.POMODORO_BACK',
      POMODORO_DISABLE: 'F.FOCUS_MODE.POMODORO_DISABLE',
      POMODORO_INFO: 'F.FOCUS_MODE.POMODORO_INFO',
      PREP_GET_MENTALLY_READY: 'F.FOCUS_MODE.PREP_GET_MENTALLY_READY',
      PREP_SIT_UPRIGHT: 'F.FOCUS_MODE.PREP_SIT_UPRIGHT',
      PREP_STRETCH: 'F.FOCUS_MODE.PREP_STRETCH',
      SELECT_ANOTHER_TASK: 'F.FOCUS_MODE.SELECT_ANOTHER_TASK',
      SELECT_TASK: 'F.FOCUS_MODE.SELECT_TASK',
      SET_FOCUS_SESSION_DURATION: 'F.FOCUS_MODE.SET_FOCUS_SESSION_DURATION',
      SHOW_HIDE_NOTES_AND_ATTACHMENTS: 'F.FOCUS_MODE.SHOW_HIDE_NOTES_AND_ATTACHMENTS',
      START_FOCUS_SESSION: 'F.FOCUS_MODE.START_FOCUS_SESSION',
      START_NEXT_FOCUS_SESSION: 'F.FOCUS_MODE.START_NEXT_FOCUS_SESSION',
      WORKED_FOR: 'F.FOCUS_MODE.WORKED_FOR',
    },
    GITEA: {
      DIALOG_INITIAL: {
        TITLE: 'F.GITEA.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        FILTER_USER: 'F.GITEA.FORM.FILTER_USER',
        HOST: 'F.GITEA.FORM.HOST',
        REPO_FULL_NAME: 'F.GITEA.FORM.REPO_FULL_NAME',
        REPO_FULL_NAME_DESCRIPTION: 'F.GITEA.FORM.REPO_FULL_NAME_DESCRIPTION',
        SCOPE: 'F.GITEA.FORM.SCOPE',
        SCOPE_ALL: 'F.GITEA.FORM.SCOPE_ALL',
        SCOPE_ASSIGNED: 'F.GITEA.FORM.SCOPE_ASSIGNED',
        SCOPE_CREATED: 'F.GITEA.FORM.SCOPE_CREATED',
        TOKEN: 'F.GITEA.FORM.TOKEN',
      },
      FORM_SECTION: {
        HELP: 'F.GITEA.FORM_SECTION.HELP',
        TITLE: 'F.GITEA.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.GITEA.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.GITEA.ISSUE_CONTENT.AT',
        DESCRIPTION: 'F.GITEA.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.GITEA.ISSUE_CONTENT.LABELS',
        MARK_AS_CHECKED: 'F.GITEA.ISSUE_CONTENT.MARK_AS_CHECKED',
        PROJECT: 'F.GITEA.ISSUE_CONTENT.PROJECT',
        STATUS: 'F.GITEA.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.GITEA.ISSUE_CONTENT.SUMMARY',
        WRITE_A_COMMENT: 'F.GITEA.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        ERR_UNKNOWN: 'F.GITEA.S.ERR_UNKNOWN',
      },
    },
    GITHUB: {
      DIALOG_INITIAL: {
        TITLE: 'F.GITHUB.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        FILTER_USER: 'F.GITHUB.FORM.FILTER_USER',
        IS_ASSIGNEE_FILTER: 'F.GITHUB.FORM.IS_ASSIGNEE_FILTER',
        REPO: 'F.GITHUB.FORM.REPO',
        TOKEN: 'F.GITHUB.FORM.TOKEN',
      },
      FORM_SECTION: {
        HELP: 'F.GITHUB.FORM_SECTION.HELP',
        TITLE: 'F.GITHUB.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.GITHUB.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.GITHUB.ISSUE_CONTENT.AT',
        DESCRIPTION: 'F.GITHUB.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.GITHUB.ISSUE_CONTENT.LABELS',
        LAST_COMMENT: 'F.GITHUB.ISSUE_CONTENT.LAST_COMMENT',
        LOAD_ALL_COMMENTS: 'F.GITHUB.ISSUE_CONTENT.LOAD_ALL_COMMENTS',
        LOAD_DESCRIPTION_AND_ALL_COMMENTS:
          'F.GITHUB.ISSUE_CONTENT.LOAD_DESCRIPTION_AND_ALL_COMMENTS',
        MARK_AS_CHECKED: 'F.GITHUB.ISSUE_CONTENT.MARK_AS_CHECKED',
        STATUS: 'F.GITHUB.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.GITHUB.ISSUE_CONTENT.SUMMARY',
        WRITE_A_COMMENT: 'F.GITHUB.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        CONFIG_ERROR: 'F.GITHUB.S.CONFIG_ERROR',
        ERR_UNKNOWN: 'F.GITHUB.S.ERR_UNKNOWN',
      },
    },
    GITLAB: {
      DIALOG_INITIAL: {
        TITLE: 'F.GITLAB.DIALOG_INITIAL.TITLE',
      },
      DIALOG_SUBMIT_WORKLOG: {
        PAST_DAY_INFO: 'F.GITLAB.DIALOG_SUBMIT_WORKLOG.PAST_DAY_INFO',
        T_ALREADY_TRACKED: 'F.GITLAB.DIALOG_SUBMIT_WORKLOG.T_ALREADY_TRACKED',
        T_TITLE: 'F.GITLAB.DIALOG_SUBMIT_WORKLOG.T_TITLE',
        T_TO_BE_SUBMITTED: 'F.GITLAB.DIALOG_SUBMIT_WORKLOG.T_TO_BE_SUBMITTED',
        TITLE: 'F.GITLAB.DIALOG_SUBMIT_WORKLOG.TITLE',
        TOTAL_MSG: 'F.GITLAB.DIALOG_SUBMIT_WORKLOG.TOTAL_MSG',
      },
      FORM: {
        FILTER: 'F.GITLAB.FORM.FILTER',
        FILTER_DESCRIPTION: 'F.GITLAB.FORM.FILTER_DESCRIPTION',
        FILTER_USER: 'F.GITLAB.FORM.FILTER_USER',
        GITLAB_BASE_URL: 'F.GITLAB.FORM.GITLAB_BASE_URL',
        PROJECT: 'F.GITLAB.FORM.PROJECT',
        PROJECT_HINT: 'F.GITLAB.FORM.PROJECT_HINT',
        SCOPE: 'F.GITLAB.FORM.SCOPE',
        SCOPE_ALL: 'F.GITLAB.FORM.SCOPE_ALL',
        SCOPE_ASSIGNED: 'F.GITLAB.FORM.SCOPE_ASSIGNED',
        SCOPE_CREATED: 'F.GITLAB.FORM.SCOPE_CREATED',
        SOURCE: 'F.GITLAB.FORM.SOURCE',
        SOURCE_GLOBAL: 'F.GITLAB.FORM.SOURCE_GLOBAL',
        SOURCE_GROUP: 'F.GITLAB.FORM.SOURCE_GROUP',
        SOURCE_PROJECT: 'F.GITLAB.FORM.SOURCE_PROJECT',
        SUBMIT_TIMELOGS: 'F.GITLAB.FORM.SUBMIT_TIMELOGS',
        SUBMIT_TIMELOGS_DESCRIPTION: 'F.GITLAB.FORM.SUBMIT_TIMELOGS_DESCRIPTION',
        TOKEN: 'F.GITLAB.FORM.TOKEN',
      },
      FORM_SECTION: {
        HELP: 'F.GITLAB.FORM_SECTION.HELP',
        TITLE: 'F.GITLAB.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.GITLAB.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.GITLAB.ISSUE_CONTENT.AT',
        DESCRIPTION: 'F.GITLAB.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.GITLAB.ISSUE_CONTENT.LABELS',
        MARK_AS_CHECKED: 'F.GITLAB.ISSUE_CONTENT.MARK_AS_CHECKED',
        PROJECT: 'F.GITLAB.ISSUE_CONTENT.PROJECT',
        STATUS: 'F.GITLAB.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.GITLAB.ISSUE_CONTENT.SUMMARY',
        WRITE_A_COMMENT: 'F.GITLAB.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        ERR_UNKNOWN: 'F.GITLAB.S.ERR_UNKNOWN',
      },
    },
    ISSUE: {
      CROSS_ORIGIN_BROWSER_WARNING: 'F.ISSUE.CROSS_ORIGIN_BROWSER_WARNING',
      DEFAULT: {
        ISSUE_STR: 'F.ISSUE.DEFAULT.ISSUE_STR',
        ISSUES_STR: 'F.ISSUE.DEFAULT.ISSUES_STR',
      },
      DEFAULT_PROJECT_DESCRIPTION: 'F.ISSUE.DEFAULT_PROJECT_DESCRIPTION',
      DEFAULT_PROJECT_LABEL: 'F.ISSUE.DEFAULT_PROJECT_LABEL',
      HOW_TO_GET_A_TOKEN: 'F.ISSUE.HOW_TO_GET_A_TOKEN',
      S: {
        ERR_GENERIC: 'F.ISSUE.S.ERR_GENERIC',
        ERR_NETWORK: 'F.ISSUE.S.ERR_NETWORK',
        ERR_NOT_CONFIGURED: 'F.ISSUE.S.ERR_NOT_CONFIGURED',
        IMPORTED_MULTIPLE_ISSUES: 'F.ISSUE.S.IMPORTED_MULTIPLE_ISSUES',
        IMPORTED_SINGLE_ISSUE: 'F.ISSUE.S.IMPORTED_SINGLE_ISSUE',
        ISSUE_DELETED_OR_CLOSED: 'F.ISSUE.S.ISSUE_DELETED_OR_CLOSED',
        ISSUE_NO_UPDATE_REQUIRED: 'F.ISSUE.S.ISSUE_NO_UPDATE_REQUIRED',
        ISSUE_UPDATE_MULTIPLE: 'F.ISSUE.S.ISSUE_UPDATE_MULTIPLE',
        ISSUE_UPDATE_SINGLE: 'F.ISSUE.S.ISSUE_UPDATE_SINGLE',
        MANUAL_UPDATE_ISSUE_SUCCESS: 'F.ISSUE.S.MANUAL_UPDATE_ISSUE_SUCCESS',
        MISSING_ISSUE_DATA: 'F.ISSUE.S.MISSING_ISSUE_DATA',
        NEW_COMMENT: 'F.ISSUE.S.NEW_COMMENT',
        POLLING_BACKLOG: 'F.ISSUE.S.POLLING_BACKLOG',
        POLLING_CHANGES: 'F.ISSUE.S.POLLING_CHANGES',
      },
    },
    JIRA: {
      BANNER: {
        BLOCK_ACCESS_MSG: 'F.JIRA.BANNER.BLOCK_ACCESS_MSG',
        BLOCK_ACCESS_UNBLOCK: 'F.JIRA.BANNER.BLOCK_ACCESS_UNBLOCK',
      },
      CFG_CMP: {
        ALWAYS_ASK: 'F.JIRA.CFG_CMP.ALWAYS_ASK',
        DO_NOT: 'F.JIRA.CFG_CMP.DO_NOT',
        DONE: 'F.JIRA.CFG_CMP.DONE',
        ENABLE: 'F.JIRA.CFG_CMP.ENABLE',
        ENABLE_TRANSITIONS: 'F.JIRA.CFG_CMP.ENABLE_TRANSITIONS',
        IN_PROGRESS: 'F.JIRA.CFG_CMP.IN_PROGRESS',
        LOAD_SUGGESTIONS: 'F.JIRA.CFG_CMP.LOAD_SUGGESTIONS',
        MAP_CUSTOM_FIELDS: 'F.JIRA.CFG_CMP.MAP_CUSTOM_FIELDS',
        MAP_CUSTOM_FIELDS_INFO: 'F.JIRA.CFG_CMP.MAP_CUSTOM_FIELDS_INFO',
        OPEN: 'F.JIRA.CFG_CMP.OPEN',
        SELECT_ISSUE_FOR_TRANSITIONS: 'F.JIRA.CFG_CMP.SELECT_ISSUE_FOR_TRANSITIONS',
        STORY_POINTS: 'F.JIRA.CFG_CMP.STORY_POINTS',
        TRANSITION: 'F.JIRA.CFG_CMP.TRANSITION',
      },
      DIALOG_CONFIRM_ASSIGNMENT: {
        MSG: 'F.JIRA.DIALOG_CONFIRM_ASSIGNMENT.MSG',
        OK: 'F.JIRA.DIALOG_CONFIRM_ASSIGNMENT.OK',
      },
      DIALOG_INITIAL: {
        TITLE: 'F.JIRA.DIALOG_INITIAL.TITLE',
      },
      DIALOG_TRANSITION: {
        CHOOSE_STATUS: 'F.JIRA.DIALOG_TRANSITION.CHOOSE_STATUS',
        CURRENT_ASSIGNEE: 'F.JIRA.DIALOG_TRANSITION.CURRENT_ASSIGNEE',
        CURRENT_STATUS: 'F.JIRA.DIALOG_TRANSITION.CURRENT_STATUS',
        TASK_NAME: 'F.JIRA.DIALOG_TRANSITION.TASK_NAME',
        TITLE: 'F.JIRA.DIALOG_TRANSITION.TITLE',
        UPDATE_STATUS: 'F.JIRA.DIALOG_TRANSITION.UPDATE_STATUS',
      },
      DIALOG_WORKLOG: {
        CHECKBOXES: {
          ALL_TIME: 'F.JIRA.DIALOG_WORKLOG.CHECKBOXES.ALL_TIME',
          ALL_TIME_MINUS_LOGGED: 'F.JIRA.DIALOG_WORKLOG.CHECKBOXES.ALL_TIME_MINUS_LOGGED',
          TIME_SPENT_TODAY: 'F.JIRA.DIALOG_WORKLOG.CHECKBOXES.TIME_SPENT_TODAY',
          TIME_SPENT_YESTERDAY: 'F.JIRA.DIALOG_WORKLOG.CHECKBOXES.TIME_SPENT_YESTERDAY',
        },
        CURRENTLY_LOGGED: 'F.JIRA.DIALOG_WORKLOG.CURRENTLY_LOGGED',
        INVALID_DATE: 'F.JIRA.DIALOG_WORKLOG.INVALID_DATE',
        SAVE_WORKLOG: 'F.JIRA.DIALOG_WORKLOG.SAVE_WORKLOG',
        STARTED: 'F.JIRA.DIALOG_WORKLOG.STARTED',
        SUBMIT_WORKLOG_FOR: 'F.JIRA.DIALOG_WORKLOG.SUBMIT_WORKLOG_FOR',
        TIME_SPENT: 'F.JIRA.DIALOG_WORKLOG.TIME_SPENT',
        TIME_SPENT_TOOLTIP: 'F.JIRA.DIALOG_WORKLOG.TIME_SPENT_TOOLTIP',
        TITLE: 'F.JIRA.DIALOG_WORKLOG.TITLE',
      },
      FORM_ADV: {
        AUTO_ADD_BACKLOG_JQL_QUERY: 'F.JIRA.FORM_ADV.AUTO_ADD_BACKLOG_JQL_QUERY',
        IS_ADD_WORKLOG_ON_SUB_TASK_DONE:
          'F.JIRA.FORM_ADV.IS_ADD_WORKLOG_ON_SUB_TASK_DONE',
        IS_CHECK_TO_RE_ASSIGN_TICKET_ON_TASK_START:
          'F.JIRA.FORM_ADV.IS_CHECK_TO_RE_ASSIGN_TICKET_ON_TASK_START',
        IS_WORKLOG_ENABLED: 'F.JIRA.FORM_ADV.IS_WORKLOG_ENABLED',
        SEARCH_JQL_QUERY: 'F.JIRA.FORM_ADV.SEARCH_JQL_QUERY',
        WORKLOG_DEFAULT_ALL_TIME: 'F.JIRA.FORM_ADV.WORKLOG_DEFAULT_ALL_TIME',
        WORKLOG_DEFAULT_ALL_TIME_MINUS_LOGGED:
          'F.JIRA.FORM_ADV.WORKLOG_DEFAULT_ALL_TIME_MINUS_LOGGED',
        WORKLOG_DEFAULT_TIME_MODE: 'F.JIRA.FORM_ADV.WORKLOG_DEFAULT_TIME_MODE',
        WORKLOG_DEFAULT_TODAY: 'F.JIRA.FORM_ADV.WORKLOG_DEFAULT_TODAY',
        WORKLOG_DEFAULT_YESTERDAY: 'F.JIRA.FORM_ADV.WORKLOG_DEFAULT_YESTERDAY',
      },
      FORM_CRED: {
        ALLOW_SELF_SIGNED: 'F.JIRA.FORM_CRED.ALLOW_SELF_SIGNED',
        HOST: 'F.JIRA.FORM_CRED.HOST',
        PASSWORD: 'F.JIRA.FORM_CRED.PASSWORD',
        USE_PAT: 'F.JIRA.FORM_CRED.USE_PAT',
        USER_NAME: 'F.JIRA.FORM_CRED.USER_NAME',
        WONKY_COOKIE_MODE: 'F.JIRA.FORM_CRED.WONKY_COOKIE_MODE',
      },
      FORM_SECTION: {
        ADV_CFG: 'F.JIRA.FORM_SECTION.ADV_CFG',
        HELP_ARR: {
          H1: 'F.JIRA.FORM_SECTION.HELP_ARR.H1',
          H2: 'F.JIRA.FORM_SECTION.HELP_ARR.H2',
          H3: 'F.JIRA.FORM_SECTION.HELP_ARR.H3',
          P1_1: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_1',
          P1_2: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_2',
          P1_3: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_3',
          P1_4: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_4',
          P2_1: 'F.JIRA.FORM_SECTION.HELP_ARR.P2_1',
          P2_2: 'F.JIRA.FORM_SECTION.HELP_ARR.P2_2',
          P2_3: 'F.JIRA.FORM_SECTION.HELP_ARR.P2_3',
          P3_1: 'F.JIRA.FORM_SECTION.HELP_ARR.P3_1',
        },
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.JIRA.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.JIRA.ISSUE_CONTENT.AT',
        ATTACHMENTS: 'F.JIRA.ISSUE_CONTENT.ATTACHMENTS',
        CHANGED: 'F.JIRA.ISSUE_CONTENT.CHANGED',
        COMMENTS: 'F.JIRA.ISSUE_CONTENT.COMMENTS',
        COMPONENTS: 'F.JIRA.ISSUE_CONTENT.COMPONENTS',
        DESCRIPTION: 'F.JIRA.ISSUE_CONTENT.DESCRIPTION',
        LIST_OF_CHANGES: 'F.JIRA.ISSUE_CONTENT.LIST_OF_CHANGES',
        MARK_AS_CHECKED: 'F.JIRA.ISSUE_CONTENT.MARK_AS_CHECKED',
        ON: 'F.JIRA.ISSUE_CONTENT.ON',
        RELATED: 'F.JIRA.ISSUE_CONTENT.RELATED',
        STATUS: 'F.JIRA.ISSUE_CONTENT.STATUS',
        STORY_POINTS: 'F.JIRA.ISSUE_CONTENT.STORY_POINTS',
        SUB_TASKS: 'F.JIRA.ISSUE_CONTENT.SUB_TASKS',
        SUMMARY: 'F.JIRA.ISSUE_CONTENT.SUMMARY',
        WORKLOG: 'F.JIRA.ISSUE_CONTENT.WORKLOG',
        WRITE_A_COMMENT: 'F.JIRA.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        ADDED_WORKLOG_FOR: 'F.JIRA.S.ADDED_WORKLOG_FOR',
        EXTENSION_NOT_LOADED: 'F.JIRA.S.EXTENSION_NOT_LOADED',
        INSUFFICIENT_SETTINGS: 'F.JIRA.S.INSUFFICIENT_SETTINGS',
        INVALID_RESPONSE: 'F.JIRA.S.INVALID_RESPONSE',
        ISSUE_NO_UPDATE_REQUIRED: 'F.JIRA.S.ISSUE_NO_UPDATE_REQUIRED',
        MANUAL_UPDATE_ISSUE_SUCCESS: 'F.JIRA.S.MANUAL_UPDATE_ISSUE_SUCCESS',
        MISSING_ISSUE_DATA: 'F.JIRA.S.MISSING_ISSUE_DATA',
        NO_AUTO_IMPORT_JQL: 'F.JIRA.S.NO_AUTO_IMPORT_JQL',
        NO_VALID_TRANSITION: 'F.JIRA.S.NO_VALID_TRANSITION',
        TIMED_OUT: 'F.JIRA.S.TIMED_OUT',
        TRANSITION: 'F.JIRA.S.TRANSITION',
        TRANSITION_SUCCESS: 'F.JIRA.S.TRANSITION_SUCCESS',
        TRANSITIONS_LOADED: 'F.JIRA.S.TRANSITIONS_LOADED',
        UNABLE_TO_REASSIGN: 'F.JIRA.S.UNABLE_TO_REASSIGN',
      },
      STEPPER: {
        CREDENTIALS: 'F.JIRA.STEPPER.CREDENTIALS',
        DONE: 'F.JIRA.STEPPER.DONE',
        LOGIN_SUCCESS: 'F.JIRA.STEPPER.LOGIN_SUCCESS',
        TEST_CREDENTIALS: 'F.JIRA.STEPPER.TEST_CREDENTIALS',
        WELCOME_USER: 'F.JIRA.STEPPER.WELCOME_USER',
      },
    },
    METRIC: {
      BANNER: {
        CHECK: 'F.METRIC.BANNER.CHECK',
      },
      CMP: {
        AVG_BREAKS_PER_DAY: 'F.METRIC.CMP.AVG_BREAKS_PER_DAY',
        AVG_TASKS_PER_DAY_WORKED: 'F.METRIC.CMP.AVG_TASKS_PER_DAY_WORKED',
        AVG_TIME_SPENT_ON_BREAKS: 'F.METRIC.CMP.AVG_TIME_SPENT_ON_BREAKS',
        AVG_TIME_SPENT_PER_DAY: 'F.METRIC.CMP.AVG_TIME_SPENT_PER_DAY',
        AVG_TIME_SPENT_PER_TASK: 'F.METRIC.CMP.AVG_TIME_SPENT_PER_TASK',
        COUNTING_SUBTASKS: 'F.METRIC.CMP.COUNTING_SUBTASKS',
        DAYS_WORKED: 'F.METRIC.CMP.DAYS_WORKED',
        GLOBAL_METRICS: 'F.METRIC.CMP.GLOBAL_METRICS',
        IMPROVEMENT_SELECTION_COUNT: 'F.METRIC.CMP.IMPROVEMENT_SELECTION_COUNT',
        MOOD_PRODUCTIVITY_OVER_TIME: 'F.METRIC.CMP.MOOD_PRODUCTIVITY_OVER_TIME',
        NO_ADDITIONAL_DATA_YET: 'F.METRIC.CMP.NO_ADDITIONAL_DATA_YET',
        OBSTRUCTION_SELECTION_COUNT: 'F.METRIC.CMP.OBSTRUCTION_SELECTION_COUNT',
        SIMPLE_CLICK_COUNTERS_OVER_TIME: 'F.METRIC.CMP.SIMPLE_CLICK_COUNTERS_OVER_TIME',
        SIMPLE_COUNTERS: 'F.METRIC.CMP.SIMPLE_COUNTERS',
        SIMPLE_STOPWATCH_COUNTERS_OVER_TIME:
          'F.METRIC.CMP.SIMPLE_STOPWATCH_COUNTERS_OVER_TIME',
        TASKS_DONE_CREATED: 'F.METRIC.CMP.TASKS_DONE_CREATED',
        TIME_ESTIMATED: 'F.METRIC.CMP.TIME_ESTIMATED',
        TIME_SPENT: 'F.METRIC.CMP.TIME_SPENT',
      },
      EVAL_FORM: {
        ADD_NOTE_FOR_TOMORROW: 'F.METRIC.EVAL_FORM.ADD_NOTE_FOR_TOMORROW',
        DISABLE_REPEAT_EVERY_DAY: 'F.METRIC.EVAL_FORM.DISABLE_REPEAT_EVERY_DAY',
        ENABLE_REPEAT_EVERY_DAY: 'F.METRIC.EVAL_FORM.ENABLE_REPEAT_EVERY_DAY',
        HELP_H1: 'F.METRIC.EVAL_FORM.HELP_H1',
        HELP_LINK_TXT: 'F.METRIC.EVAL_FORM.HELP_LINK_TXT',
        HELP_P1: 'F.METRIC.EVAL_FORM.HELP_P1',
        HELP_P2: 'F.METRIC.EVAL_FORM.HELP_P2',
        IMPROVEMENTS: 'F.METRIC.EVAL_FORM.IMPROVEMENTS',
        IMPROVEMENTS_TOMORROW: 'F.METRIC.EVAL_FORM.IMPROVEMENTS_TOMORROW',
        MOOD: 'F.METRIC.EVAL_FORM.MOOD',
        MOOD_HINT: 'F.METRIC.EVAL_FORM.MOOD_HINT',
        NOTES: 'F.METRIC.EVAL_FORM.NOTES',
        OBSTRUCTIONS: 'F.METRIC.EVAL_FORM.OBSTRUCTIONS',
        PRODUCTIVITY: 'F.METRIC.EVAL_FORM.PRODUCTIVITY',
        PRODUCTIVITY_HINT: 'F.METRIC.EVAL_FORM.PRODUCTIVITY_HINT',
      },
      S: {
        SAVE_METRIC: 'F.METRIC.S.SAVE_METRIC',
      },
    },
    NOTE: {
      D_ADD: {
        NOTE_LABEL: 'F.NOTE.D_ADD.NOTE_LABEL',
      },
      D_FULLSCREEN: {
        VIEW_PARSED: 'F.NOTE.D_FULLSCREEN.VIEW_PARSED',
        VIEW_SPLIT: 'F.NOTE.D_FULLSCREEN.VIEW_SPLIT',
        VIEW_TEXT_ONLY: 'F.NOTE.D_FULLSCREEN.VIEW_TEXT_ONLY',
      },
      NOTE_CMP: {
        DISABLE_PARSE: 'F.NOTE.NOTE_CMP.DISABLE_PARSE',
        ENABLE_PARSE: 'F.NOTE.NOTE_CMP.ENABLE_PARSE',
      },
      NOTES_CMP: {
        ADD_BTN: 'F.NOTE.NOTES_CMP.ADD_BTN',
        DROP_TO_ADD: 'F.NOTE.NOTES_CMP.DROP_TO_ADD',
        NO_NOTES: 'F.NOTE.NOTES_CMP.NO_NOTES',
      },
      S: {
        NOTE_ADDED: 'F.NOTE.S.NOTE_ADDED',
      },
    },
    OPEN_PROJECT: {
      CFG_CMP: {
        ALWAYS_ASK: 'F.OPEN_PROJECT.CFG_CMP.ALWAYS_ASK',
        DO_NOT: 'F.OPEN_PROJECT.CFG_CMP.DO_NOT',
        DONE: 'F.OPEN_PROJECT.CFG_CMP.DONE',
        ENABLE: 'F.OPEN_PROJECT.CFG_CMP.ENABLE',
        ENABLE_TRANSITIONS: 'F.OPEN_PROJECT.CFG_CMP.ENABLE_TRANSITIONS',
        IN_PROGRESS: 'F.OPEN_PROJECT.CFG_CMP.IN_PROGRESS',
        OPEN: 'F.OPEN_PROJECT.CFG_CMP.OPEN',
        PROGRESS_ON_SAVE: 'F.OPEN_PROJECT.CFG_CMP.PROGRESS_ON_SAVE',
        SELECT_ISSUE_FOR_TRANSITIONS:
          'F.OPEN_PROJECT.CFG_CMP.SELECT_ISSUE_FOR_TRANSITIONS',
        TRANSITION: 'F.OPEN_PROJECT.CFG_CMP.TRANSITION',
      },
      DIALOG_INITIAL: {
        TITLE: 'F.OPEN_PROJECT.DIALOG_INITIAL.TITLE',
      },
      DIALOG_TRACK_TIME: {
        ACTIVITY: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.ACTIVITY',
        CURRENTLY_LOGGED: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.CURRENTLY_LOGGED',
        INVALID_DATE: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.INVALID_DATE',
        POST_TIME: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.POST_TIME',
        STARTED: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.STARTED',
        SUBMIT_TIME_FOR: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.SUBMIT_TIME_FOR',
        TIME_SPENT: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.TIME_SPENT',
        TITLE: 'F.OPEN_PROJECT.DIALOG_TRACK_TIME.TITLE',
      },
      DIALOG_TRANSITION: {
        CHOOSE_STATUS: 'F.OPEN_PROJECT.DIALOG_TRANSITION.CHOOSE_STATUS',
        CURRENT_ASSIGNEE: 'F.OPEN_PROJECT.DIALOG_TRANSITION.CURRENT_ASSIGNEE',
        CURRENT_STATUS: 'F.OPEN_PROJECT.DIALOG_TRANSITION.CURRENT_STATUS',
        PERCENTAGE_DONE: 'F.OPEN_PROJECT.DIALOG_TRANSITION.PERCENTAGE_DONE',
        TASK_NAME: 'F.OPEN_PROJECT.DIALOG_TRANSITION.TASK_NAME',
        TITLE: 'F.OPEN_PROJECT.DIALOG_TRANSITION.TITLE',
        UPDATE_STATUS: 'F.OPEN_PROJECT.DIALOG_TRANSITION.UPDATE_STATUS',
      },
      FORM: {
        FILTER_USER: 'F.OPEN_PROJECT.FORM.FILTER_USER',
        HOST: 'F.OPEN_PROJECT.FORM.HOST',
        IS_SHOW_TIME_TRACKING_DIALOG: 'F.OPEN_PROJECT.FORM.IS_SHOW_TIME_TRACKING_DIALOG',
        IS_SHOW_TIME_TRACKING_DIALOG_DESCRIPTION:
          'F.OPEN_PROJECT.FORM.IS_SHOW_TIME_TRACKING_DIALOG_DESCRIPTION',
        IS_SHOW_TIME_TRACKING_DIALOG_FOR_EACH_SUB_TASK:
          'F.OPEN_PROJECT.FORM.IS_SHOW_TIME_TRACKING_DIALOG_FOR_EACH_SUB_TASK',
        PROJECT_ID: 'F.OPEN_PROJECT.FORM.PROJECT_ID',
        PROJECT_ID_DESCRIPTION: 'F.OPEN_PROJECT.FORM.PROJECT_ID_DESCRIPTION',
        SCOPE: 'F.OPEN_PROJECT.FORM.SCOPE',
        SCOPE_ALL: 'F.OPEN_PROJECT.FORM.SCOPE_ALL',
        SCOPE_ASSIGNED: 'F.OPEN_PROJECT.FORM.SCOPE_ASSIGNED',
        SCOPE_CREATED: 'F.OPEN_PROJECT.FORM.SCOPE_CREATED',
        TOKEN: 'F.OPEN_PROJECT.FORM.TOKEN',
      },
      FORM_SECTION: {
        HELP: 'F.OPEN_PROJECT.FORM_SECTION.HELP',
        TITLE: 'F.OPEN_PROJECT.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.OPEN_PROJECT.ISSUE_CONTENT.ASSIGNEE',
        DESCRIPTION: 'F.OPEN_PROJECT.ISSUE_CONTENT.DESCRIPTION',
        MARK_AS_CHECKED: 'F.OPEN_PROJECT.ISSUE_CONTENT.MARK_AS_CHECKED',
        STATUS: 'F.OPEN_PROJECT.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.OPEN_PROJECT.ISSUE_CONTENT.SUMMARY',
        TYPE: 'F.OPEN_PROJECT.ISSUE_CONTENT.TYPE',
      },
      ISSUE_STRINGS: {
        ISSUE_STR: 'F.OPEN_PROJECT.ISSUE_STRINGS.ISSUE_STR',
        ISSUES_STR: 'F.OPEN_PROJECT.ISSUE_STRINGS.ISSUES_STR',
      },
      S: {
        ERR_UNKNOWN: 'F.OPEN_PROJECT.S.ERR_UNKNOWN',
        POST_TIME_SUCCESS: 'F.OPEN_PROJECT.S.POST_TIME_SUCCESS',
        TRANSITION: 'F.OPEN_PROJECT.S.TRANSITION',
        TRANSITION_SUCCESS: 'F.OPEN_PROJECT.S.TRANSITION_SUCCESS',
        TRANSITIONS_LOADED: 'F.OPEN_PROJECT.S.TRANSITIONS_LOADED',
      },
    },
    PLANNER: {
      D: {
        ADD_PLANNED: {
          ADD_TO_TODAY: 'F.PLANNER.D.ADD_PLANNED.ADD_TO_TODAY',
          TITLE: 'F.PLANNER.D.ADD_PLANNED.TITLE',
        },
        PLAN_FOR_DAY: {
          REMINDER_WARNING: 'F.PLANNER.D.PLAN_FOR_DAY.REMINDER_WARNING',
          REMOVE_PLAN_DATE: 'F.PLANNER.D.PLAN_FOR_DAY.REMOVE_PLAN_DATE',
          TITLE: 'F.PLANNER.D.PLAN_FOR_DAY.TITLE',
        },
      },
      EDIT_REPEATED_TASK: 'F.PLANNER.EDIT_REPEATED_TASK',
      NO_TASKS: 'F.PLANNER.NO_TASKS',
      PLAN_VIEW: {
        NO_SCHEDULED_ITEMS: 'F.PLANNER.PLAN_VIEW.NO_SCHEDULED_ITEMS',
      },
      S: {
        REMOVED_PLAN_DATE: 'F.PLANNER.S.REMOVED_PLAN_DATE',
        TASK_ALREADY_PLANNED: 'F.PLANNER.S.TASK_ALREADY_PLANNED',
        TASK_PLANNED_FOR: 'F.PLANNER.S.TASK_PLANNED_FOR',
      },
      TASK_DRAWER: 'F.PLANNER.TASK_DRAWER',
    },
    POMODORO: {
      BACK_TO_WORK: 'F.POMODORO.BACK_TO_WORK',
      BREAK_IS_DONE: 'F.POMODORO.BREAK_IS_DONE',
      ENJOY_YOURSELF: 'F.POMODORO.ENJOY_YOURSELF',
      FINISH_SESSION_X: 'F.POMODORO.FINISH_SESSION_X',
      NOTIFICATION: {
        BREAK_TIME: 'F.POMODORO.NOTIFICATION.BREAK_TIME',
        BREAK_X_START: 'F.POMODORO.NOTIFICATION.BREAK_X_START',
        NO_TASKS: 'F.POMODORO.NOTIFICATION.NO_TASKS',
        SESSION_X_START: 'F.POMODORO.NOTIFICATION.SESSION_X_START',
      },
      S: {
        RESET: 'F.POMODORO.S.RESET',
        SESSION_SKIP: 'F.POMODORO.S.SESSION_SKIP',
        SESSION_X_START: 'F.POMODORO.S.SESSION_X_START',
      },
      SKIP_BREAK: 'F.POMODORO.SKIP_BREAK',
      START_BREAK: 'F.POMODORO.START_BREAK',
    },
    PROCRASTINATION: {
      BACK_TO_WORK: 'F.PROCRASTINATION.BACK_TO_WORK',
      COMP: {
        INTRO: 'F.PROCRASTINATION.COMP.INTRO',
        L1: 'F.PROCRASTINATION.COMP.L1',
        L2: 'F.PROCRASTINATION.COMP.L2',
        L3: 'F.PROCRASTINATION.COMP.L3',
        L4: 'F.PROCRASTINATION.COMP.L4',
        OUTRO: 'F.PROCRASTINATION.COMP.OUTRO',
        TITLE: 'F.PROCRASTINATION.COMP.TITLE',
      },
      CUR: {
        INTRO: 'F.PROCRASTINATION.CUR.INTRO',
        L1: 'F.PROCRASTINATION.CUR.L1',
        L2: 'F.PROCRASTINATION.CUR.L2',
        L3: 'F.PROCRASTINATION.CUR.L3',
        L4: 'F.PROCRASTINATION.CUR.L4',
        L5: 'F.PROCRASTINATION.CUR.L5',
        PROCRASTINATION_TRIGGERS_TEXT:
          'F.PROCRASTINATION.CUR.PROCRASTINATION_TRIGGERS_TEXT',
        PROCRASTINATION_TRIGGERS_TITLE:
          'F.PROCRASTINATION.CUR.PROCRASTINATION_TRIGGERS_TITLE',
        TITLE: 'F.PROCRASTINATION.CUR.TITLE',
      },
      H1: 'F.PROCRASTINATION.H1',
      INTRO: {
        AVOIDING: 'F.PROCRASTINATION.INTRO.AVOIDING',
        FEAR: 'F.PROCRASTINATION.INTRO.FEAR',
        STRESSED: 'F.PROCRASTINATION.INTRO.STRESSED',
        TITLE: 'F.PROCRASTINATION.INTRO.TITLE',
      },
      P1: 'F.PROCRASTINATION.P1',
      P2: 'F.PROCRASTINATION.P2',
      REFRAME: {
        INTRO: 'F.PROCRASTINATION.REFRAME.INTRO',
        TITLE: 'F.PROCRASTINATION.REFRAME.TITLE',
        TL1: 'F.PROCRASTINATION.REFRAME.TL1',
        TL2: 'F.PROCRASTINATION.REFRAME.TL2',
        TL3: 'F.PROCRASTINATION.REFRAME.TL3',
      },
      SPLIT_UP: {
        INTRO: 'F.PROCRASTINATION.SPLIT_UP.INTRO',
        OUTRO: 'F.PROCRASTINATION.SPLIT_UP.OUTRO',
        TITLE: 'F.PROCRASTINATION.SPLIT_UP.TITLE',
      },
    },
    PROJECT: {
      D_CREATE: {
        CREATE: 'F.PROJECT.D_CREATE.CREATE',
        EDIT: 'F.PROJECT.D_CREATE.EDIT',
        SETUP_CALDAV: 'F.PROJECT.D_CREATE.SETUP_CALDAV',
        SETUP_GIT: 'F.PROJECT.D_CREATE.SETUP_GIT',
        SETUP_GITEA_PROJECT: 'F.PROJECT.D_CREATE.SETUP_GITEA_PROJECT',
        SETUP_GITLAB: 'F.PROJECT.D_CREATE.SETUP_GITLAB',
        SETUP_JIRA: 'F.PROJECT.D_CREATE.SETUP_JIRA',
        SETUP_OPEN_PROJECT: 'F.PROJECT.D_CREATE.SETUP_OPEN_PROJECT',
        SETUP_REDMINE_PROJECT: 'F.PROJECT.D_CREATE.SETUP_REDMINE_PROJECT',
      },
      D_DELETE: {
        MSG: 'F.PROJECT.D_DELETE.MSG',
      },
      FORM_BASIC: {
        L_ENABLE_BACKLOG: 'F.PROJECT.FORM_BASIC.L_ENABLE_BACKLOG',
        L_IS_HIDDEN_FROM_MENU: 'F.PROJECT.FORM_BASIC.L_IS_HIDDEN_FROM_MENU',
        L_TITLE: 'F.PROJECT.FORM_BASIC.L_TITLE',
        TITLE: 'F.PROJECT.FORM_BASIC.TITLE',
      },
      FORM_THEME: {
        D_IS_DARK_THEME: 'F.PROJECT.FORM_THEME.D_IS_DARK_THEME',
        HELP: 'F.PROJECT.FORM_THEME.HELP',
        L_BACKGROUND_IMAGE_DARK: 'F.PROJECT.FORM_THEME.L_BACKGROUND_IMAGE_DARK',
        L_BACKGROUND_IMAGE_LIGHT: 'F.PROJECT.FORM_THEME.L_BACKGROUND_IMAGE_LIGHT',
        L_COLOR_ACCENT: 'F.PROJECT.FORM_THEME.L_COLOR_ACCENT',
        L_COLOR_PRIMARY: 'F.PROJECT.FORM_THEME.L_COLOR_PRIMARY',
        L_COLOR_WARN: 'F.PROJECT.FORM_THEME.L_COLOR_WARN',
        L_HUE_ACCENT: 'F.PROJECT.FORM_THEME.L_HUE_ACCENT',
        L_HUE_PRIMARY: 'F.PROJECT.FORM_THEME.L_HUE_PRIMARY',
        L_HUE_WARN: 'F.PROJECT.FORM_THEME.L_HUE_WARN',
        L_IS_AUTO_CONTRAST: 'F.PROJECT.FORM_THEME.L_IS_AUTO_CONTRAST',
        L_IS_DISABLE_BACKGROUND_GRADIENT:
          'F.PROJECT.FORM_THEME.L_IS_DISABLE_BACKGROUND_GRADIENT',
        L_IS_REDUCED_THEME: 'F.PROJECT.FORM_THEME.L_IS_REDUCED_THEME',
        L_THEME_COLOR: 'F.PROJECT.FORM_THEME.L_THEME_COLOR',
        L_TITLE: 'F.PROJECT.FORM_THEME.L_TITLE',
        TITLE: 'F.PROJECT.FORM_THEME.TITLE',
      },
      S: {
        ARCHIVED: 'F.PROJECT.S.ARCHIVED',
        CREATED: 'F.PROJECT.S.CREATED',
        DELETED: 'F.PROJECT.S.DELETED',
        E_EXISTS: 'F.PROJECT.S.E_EXISTS',
        E_INVALID_FILE: 'F.PROJECT.S.E_INVALID_FILE',
        ISSUE_PROVIDER_UPDATED: 'F.PROJECT.S.ISSUE_PROVIDER_UPDATED',
        UNARCHIVED: 'F.PROJECT.S.UNARCHIVED',
        UPDATED: 'F.PROJECT.S.UPDATED',
      },
    },
    QUICK_HISTORY: {
      NO_DATA: 'F.QUICK_HISTORY.NO_DATA',
      PAGE_TITLE: 'F.QUICK_HISTORY.PAGE_TITLE',
      WEEK_TITLE: 'F.QUICK_HISTORY.WEEK_TITLE',
    },
    REDMINE: {
      DIALOG_INITIAL: {
        TITLE: 'F.REDMINE.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        API_KEY: 'F.REDMINE.FORM.API_KEY',
        HOST: 'F.REDMINE.FORM.HOST',
        PROJECT_ID: 'F.REDMINE.FORM.PROJECT_ID',
        PROJECT_ID_DESCRIPTION: 'F.REDMINE.FORM.PROJECT_ID_DESCRIPTION',
        SCOPE: 'F.REDMINE.FORM.SCOPE',
        SCOPE_ALL: 'F.REDMINE.FORM.SCOPE_ALL',
        SCOPE_ASSIGNED: 'F.REDMINE.FORM.SCOPE_ASSIGNED',
        SCOPE_CREATED: 'F.REDMINE.FORM.SCOPE_CREATED',
      },
      FORM_SECTION: {
        HELP: 'F.REDMINE.FORM_SECTION.HELP',
        TITLE: 'F.REDMINE.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        AUTHOR: 'F.REDMINE.ISSUE_CONTENT.AUTHOR',
        DESCRIPTION: 'F.REDMINE.ISSUE_CONTENT.DESCRIPTION',
        MARK_AS_CHECKED: 'F.REDMINE.ISSUE_CONTENT.MARK_AS_CHECKED',
        PRIORITY: 'F.REDMINE.ISSUE_CONTENT.PRIORITY',
        STATUS: 'F.REDMINE.ISSUE_CONTENT.STATUS',
      },
      S: {
        ERR_UNKNOWN: 'F.REDMINE.S.ERR_UNKNOWN',
      },
    },
    REMINDER: {
      COUNTDOWN_BANNER: {
        HIDE: 'F.REMINDER.COUNTDOWN_BANNER.HIDE',
        START_NOW: 'F.REMINDER.COUNTDOWN_BANNER.START_NOW',
        TXT: 'F.REMINDER.COUNTDOWN_BANNER.TXT',
        TXT_MULTIPLE: 'F.REMINDER.COUNTDOWN_BANNER.TXT_MULTIPLE',
      },
      S_ACTIVE_TASK_DUE: 'F.REMINDER.S_ACTIVE_TASK_DUE',
      S_REMINDER_ERR: 'F.REMINDER.S_REMINDER_ERR',
    },
    SCHEDULE: {
      CONTINUED: 'F.SCHEDULE.CONTINUED',
      D_INITIAL: {
        TEXT: 'F.SCHEDULE.D_INITIAL.TEXT',
        TITLE: 'F.SCHEDULE.D_INITIAL.TITLE',
      },
      END: 'F.SCHEDULE.END',
      LUNCH_BREAK: 'F.SCHEDULE.LUNCH_BREAK',
      NO_TASKS: 'F.SCHEDULE.NO_TASKS',
      NOW: 'F.SCHEDULE.NOW',
      PLAN_END_DAY: 'F.SCHEDULE.PLAN_END_DAY',
      PLAN_START_DAY: 'F.SCHEDULE.PLAN_START_DAY',
      START: 'F.SCHEDULE.START',
      TASK_PROJECTION_INFO: 'F.SCHEDULE.TASK_PROJECTION_INFO',
    },
    SEARCH_BAR: {
      INFO: 'F.SEARCH_BAR.INFO',
      INFO_ARCHIVED: 'F.SEARCH_BAR.INFO_ARCHIVED',
      PLACEHOLDER: 'F.SEARCH_BAR.PLACEHOLDER',
      PLACEHOLDER_ARCHIVED: 'F.SEARCH_BAR.PLACEHOLDER_ARCHIVED',
      TOO_MANY_RESULTS: 'F.SEARCH_BAR.TOO_MANY_RESULTS',
    },
    SIMPLE_COUNTER: {
      D_CONFIRM_REMOVE: {
        MSG: 'F.SIMPLE_COUNTER.D_CONFIRM_REMOVE.MSG',
        OK: 'F.SIMPLE_COUNTER.D_CONFIRM_REMOVE.OK',
      },
      D_EDIT: {
        L_COUNTER: 'F.SIMPLE_COUNTER.D_EDIT.L_COUNTER',
        TITLE: 'F.SIMPLE_COUNTER.D_EDIT.TITLE',
      },
      FORM: {
        ADD_NEW: 'F.SIMPLE_COUNTER.FORM.ADD_NEW',
        HELP: 'F.SIMPLE_COUNTER.FORM.HELP',
        L_AUTO_COUNT_UP: 'F.SIMPLE_COUNTER.FORM.L_AUTO_COUNT_UP',
        L_AUTO_SWITCH_OFF: 'F.SIMPLE_COUNTER.FORM.L_AUTO_SWITCH_OFF',
        L_AUTO_SWITCH_ON: 'F.SIMPLE_COUNTER.FORM.L_AUTO_SWITCH_ON',
        L_COUNTDOWN_DURATION: 'F.SIMPLE_COUNTER.FORM.L_COUNTDOWN_DURATION',
        L_ICON: 'F.SIMPLE_COUNTER.FORM.L_ICON',
        L_ICON_ON: 'F.SIMPLE_COUNTER.FORM.L_ICON_ON',
        L_IS_ENABLED: 'F.SIMPLE_COUNTER.FORM.L_IS_ENABLED',
        L_TITLE: 'F.SIMPLE_COUNTER.FORM.L_TITLE',
        L_TYPE: 'F.SIMPLE_COUNTER.FORM.L_TYPE',
        TITLE: 'F.SIMPLE_COUNTER.FORM.TITLE',
        TYPE_CLICK_COUNTER: 'F.SIMPLE_COUNTER.FORM.TYPE_CLICK_COUNTER',
        TYPE_REPEATED_COUNTDOWN: 'F.SIMPLE_COUNTER.FORM.TYPE_REPEATED_COUNTDOWN',
        TYPE_STOPWATCH: 'F.SIMPLE_COUNTER.FORM.TYPE_STOPWATCH',
      },
    },
    SYNC: {
      A: {
        ARCHIVE_ONLY_UPLOADED: 'F.SYNC.A.ARCHIVE_ONLY_UPLOADED',
        POSSIBLE_LEGACY_DATA: 'F.SYNC.A.POSSIBLE_LEGACY_DATA',
      },
      C: {
        EMPTY_SYNC: 'F.SYNC.C.EMPTY_SYNC',
        FORCE_IMPORT: 'F.SYNC.C.FORCE_IMPORT',
        FORCE_UPLOAD: 'F.SYNC.C.FORCE_UPLOAD',
        FORCE_UPLOAD_AFTER_ERROR: 'F.SYNC.C.FORCE_UPLOAD_AFTER_ERROR',
        MIGRATE_LEGACY: 'F.SYNC.C.MIGRATE_LEGACY',
        NO_REMOTE_DATA: 'F.SYNC.C.NO_REMOTE_DATA',
        TRY_LOAD_REMOTE_AGAIN: 'F.SYNC.C.TRY_LOAD_REMOTE_AGAIN',
        UNABLE_TO_LOAD_REMOTE_DATA: 'F.SYNC.C.UNABLE_TO_LOAD_REMOTE_DATA',
      },
      D_AUTH_CODE: {
        FOLLOW_LINK: 'F.SYNC.D_AUTH_CODE.FOLLOW_LINK',
        GET_AUTH_CODE: 'F.SYNC.D_AUTH_CODE.GET_AUTH_CODE',
        L_AUTH_CODE: 'F.SYNC.D_AUTH_CODE.L_AUTH_CODE',
        TITLE: 'F.SYNC.D_AUTH_CODE.TITLE',
      },
      D_CONFLICT: {
        LAST_CHANGE: 'F.SYNC.D_CONFLICT.LAST_CHANGE',
        LAST_SYNC: 'F.SYNC.D_CONFLICT.LAST_SYNC',
        LOCAL: 'F.SYNC.D_CONFLICT.LOCAL',
        LOCAL_REMOTE: 'F.SYNC.D_CONFLICT.LOCAL_REMOTE',
        REMOTE: 'F.SYNC.D_CONFLICT.REMOTE',
        TEXT: 'F.SYNC.D_CONFLICT.TEXT',
        TITLE: 'F.SYNC.D_CONFLICT.TITLE',
        USE_LOCAL: 'F.SYNC.D_CONFLICT.USE_LOCAL',
        USE_REMOTE: 'F.SYNC.D_CONFLICT.USE_REMOTE',
      },
      D_INCOMPLETE_SYNC: {
        BTN_CLOSE_APP: 'F.SYNC.D_INCOMPLETE_SYNC.BTN_CLOSE_APP',
        BTN_DOWNLOAD_BACKUP: 'F.SYNC.D_INCOMPLETE_SYNC.BTN_DOWNLOAD_BACKUP',
        BTN_FORCE_UPLOAD: 'F.SYNC.D_INCOMPLETE_SYNC.BTN_FORCE_UPLOAD',
        T1: 'F.SYNC.D_INCOMPLETE_SYNC.T1',
        T2: 'F.SYNC.D_INCOMPLETE_SYNC.T2',
        T3: 'F.SYNC.D_INCOMPLETE_SYNC.T3',
        T4: 'F.SYNC.D_INCOMPLETE_SYNC.T4',
        T5: 'F.SYNC.D_INCOMPLETE_SYNC.T5',
        T6: 'F.SYNC.D_INCOMPLETE_SYNC.T6',
      },
      D_PERMISSION: {
        DISABLE_SYNC: 'F.SYNC.D_PERMISSION.DISABLE_SYNC',
        PERM_FILE: 'F.SYNC.D_PERMISSION.PERM_FILE',
        TEXT: 'F.SYNC.D_PERMISSION.TEXT',
        TITLE: 'F.SYNC.D_PERMISSION.TITLE',
      },
      FORM: {
        DROPBOX: {
          L_ACCESS_TOKEN: 'F.SYNC.FORM.DROPBOX.L_ACCESS_TOKEN',
        },
        GOOGLE: {
          L_SYNC_FILE_NAME: 'F.SYNC.FORM.GOOGLE.L_SYNC_FILE_NAME',
        },
        L_ENABLE_COMPRESSION: 'F.SYNC.FORM.L_ENABLE_COMPRESSION',
        L_ENABLE_ENCRYPTION: 'F.SYNC.FORM.L_ENABLE_ENCRYPTION',
        L_ENABLE_SYNCING: 'F.SYNC.FORM.L_ENABLE_SYNCING',
        L_ENCRYPTION_NOTES: 'F.SYNC.FORM.L_ENCRYPTION_NOTES',
        L_ENCRYPTION_PASSWORD: 'F.SYNC.FORM.L_ENCRYPTION_PASSWORD',
        L_SYNC_INTERVAL: 'F.SYNC.FORM.L_SYNC_INTERVAL',
        L_SYNC_PROVIDER: 'F.SYNC.FORM.L_SYNC_PROVIDER',
        LOCAL_FILE: {
          L_SYNC_FILE_PATH_PERMISSION_VALIDATION:
            'F.SYNC.FORM.LOCAL_FILE.L_SYNC_FILE_PATH_PERMISSION_VALIDATION',
          L_SYNC_FOLDER_PATH: 'F.SYNC.FORM.LOCAL_FILE.L_SYNC_FOLDER_PATH',
        },
        TITLE: 'F.SYNC.FORM.TITLE',
        WEB_DAV: {
          CORS_INFO: 'F.SYNC.FORM.WEB_DAV.CORS_INFO',
          L_BASE_URL: 'F.SYNC.FORM.WEB_DAV.L_BASE_URL',
          L_PASSWORD: 'F.SYNC.FORM.WEB_DAV.L_PASSWORD',
          L_SYNC_FOLDER_PATH: 'F.SYNC.FORM.WEB_DAV.L_SYNC_FOLDER_PATH',
          L_USER_NAME: 'F.SYNC.FORM.WEB_DAV.L_USER_NAME',
        },
      },
      S: {
        ALREADY_IN_SYNC: 'F.SYNC.S.ALREADY_IN_SYNC',
        ALREADY_IN_SYNC_NO_LOCAL_CHANGES: 'F.SYNC.S.ALREADY_IN_SYNC_NO_LOCAL_CHANGES',
        ERROR_FALLBACK_TO_BACKUP: 'F.SYNC.S.ERROR_FALLBACK_TO_BACKUP',
        ERROR_INVALID_DATA: 'F.SYNC.S.ERROR_INVALID_DATA',
        ERROR_NO_REV: 'F.SYNC.S.ERROR_NO_REV',
        ERROR_UNABLE_TO_READ_REMOTE_DATA: 'F.SYNC.S.ERROR_UNABLE_TO_READ_REMOTE_DATA',
        IMPORTING: 'F.SYNC.S.IMPORTING',
        INCOMPLETE_CFG: 'F.SYNC.S.INCOMPLETE_CFG',
        INITIAL_SYNC_ERROR: 'F.SYNC.S.INITIAL_SYNC_ERROR',
        SUCCESS_IMPORT: 'F.SYNC.S.SUCCESS_IMPORT',
        SUCCESS_VIA_BUTTON: 'F.SYNC.S.SUCCESS_VIA_BUTTON',
        UNKNOWN_ERROR: 'F.SYNC.S.UNKNOWN_ERROR',
        UPLOAD_ERROR: 'F.SYNC.S.UPLOAD_ERROR',
      },
    },
    TAG: {
      D_CREATE: {
        CREATE: 'F.TAG.D_CREATE.CREATE',
        EDIT: 'F.TAG.D_CREATE.EDIT',
      },
      D_DELETE: {
        CONFIRM_MSG: 'F.TAG.D_DELETE.CONFIRM_MSG',
      },
      D_EDIT: {
        ADD: 'F.TAG.D_EDIT.ADD',
        EDIT: 'F.TAG.D_EDIT.EDIT',
        LABEL: 'F.TAG.D_EDIT.LABEL',
      },
      FORM_BASIC: {
        L_COLOR: 'F.TAG.FORM_BASIC.L_COLOR',
        L_ICON: 'F.TAG.FORM_BASIC.L_ICON',
        L_TITLE: 'F.TAG.FORM_BASIC.L_TITLE',
        TITLE: 'F.TAG.FORM_BASIC.TITLE',
      },
      S: {
        UPDATED: 'F.TAG.S.UPDATED',
      },
    },
    TASK: {
      ADD_TASK_BAR: {
        ADD_EXISTING_TASK: 'F.TASK.ADD_TASK_BAR.ADD_EXISTING_TASK',
        ADD_ISSUE_TASK: 'F.TASK.ADD_TASK_BAR.ADD_ISSUE_TASK',
        ADD_TASK_TO_BOTTOM_OF_BACKLOG:
          'F.TASK.ADD_TASK_BAR.ADD_TASK_TO_BOTTOM_OF_BACKLOG',
        ADD_TASK_TO_BOTTOM_OF_TODAY: 'F.TASK.ADD_TASK_BAR.ADD_TASK_TO_BOTTOM_OF_TODAY',
        ADD_TASK_TO_TOP_OF_BACKLOG: 'F.TASK.ADD_TASK_BAR.ADD_TASK_TO_TOP_OF_BACKLOG',
        ADD_TASK_TO_TOP_OF_TODAY: 'F.TASK.ADD_TASK_BAR.ADD_TASK_TO_TOP_OF_TODAY',
        CREATE_TASK: 'F.TASK.ADD_TASK_BAR.CREATE_TASK',
        EXAMPLE: 'F.TASK.ADD_TASK_BAR.EXAMPLE',
        START: 'F.TASK.ADD_TASK_BAR.START',
        TOGGLE_ADD_TO_BACKLOG_TODAY: 'F.TASK.ADD_TASK_BAR.TOGGLE_ADD_TO_BACKLOG_TODAY',
        TOGGLE_ADD_TOP_OR_BOTTOM: 'F.TASK.ADD_TASK_BAR.TOGGLE_ADD_TOP_OR_BOTTOM',
      },
      ADDITIONAL_INFO: {
        ADD_ATTACHMENT: 'F.TASK.ADDITIONAL_INFO.ADD_ATTACHMENT',
        ADD_SUB_TASK: 'F.TASK.ADDITIONAL_INFO.ADD_SUB_TASK',
        ATTACHMENTS: 'F.TASK.ADDITIONAL_INFO.ATTACHMENTS',
        FROM_PARENT: 'F.TASK.ADDITIONAL_INFO.FROM_PARENT',
        LOCAL_ATTACHMENTS: 'F.TASK.ADDITIONAL_INFO.LOCAL_ATTACHMENTS',
        NOTES: 'F.TASK.ADDITIONAL_INFO.NOTES',
        PARENT: 'F.TASK.ADDITIONAL_INFO.PARENT',
        REMINDER: 'F.TASK.ADDITIONAL_INFO.REMINDER',
        REPEAT: 'F.TASK.ADDITIONAL_INFO.REPEAT',
        SCHEDULE_TASK: 'F.TASK.ADDITIONAL_INFO.SCHEDULE_TASK',
        SCHEDULED_AT: 'F.TASK.ADDITIONAL_INFO.SCHEDULED_AT',
        SUB_TASKS: 'F.TASK.ADDITIONAL_INFO.SUB_TASKS',
        TIME: 'F.TASK.ADDITIONAL_INFO.TIME',
        TITLE_PLACEHOLDER: 'F.TASK.ADDITIONAL_INFO.TITLE_PLACEHOLDER',
      },
      B: {
        ADD_HALF_HOUR: 'F.TASK.B.ADD_HALF_HOUR',
        ESTIMATE_EXCEEDED: 'F.TASK.B.ESTIMATE_EXCEEDED',
      },
      CMP: {
        ADD_SUB_TASK: 'F.TASK.CMP.ADD_SUB_TASK',
        ADD_TO_MY_DAY: 'F.TASK.CMP.ADD_TO_MY_DAY',
        ADD_TO_PROJECT: 'F.TASK.CMP.ADD_TO_PROJECT',
        CONVERT_TO_PARENT_TASK: 'F.TASK.CMP.CONVERT_TO_PARENT_TASK',
        DELETE: 'F.TASK.CMP.DELETE',
        DELETE_REPEAT_INSTANCE: 'F.TASK.CMP.DELETE_REPEAT_INSTANCE',
        DROP_ATTACHMENT: 'F.TASK.CMP.DROP_ATTACHMENT',
        EDIT_SCHEDULED: 'F.TASK.CMP.EDIT_SCHEDULED',
        EDIT_TAGS: 'F.TASK.CMP.EDIT_TAGS',
        EDIT_TASK_TITLE: 'F.TASK.CMP.EDIT_TASK_TITLE',
        FOCUS_SESSION: 'F.TASK.CMP.FOCUS_SESSION',
        MARK_DONE: 'F.TASK.CMP.MARK_DONE',
        MARK_UNDONE: 'F.TASK.CMP.MARK_UNDONE',
        MOVE_TO_BACKLOG: 'F.TASK.CMP.MOVE_TO_BACKLOG',
        MOVE_TO_OTHER_PROJECT: 'F.TASK.CMP.MOVE_TO_OTHER_PROJECT',
        MOVE_TO_TODAY: 'F.TASK.CMP.MOVE_TO_TODAY',
        OPEN_ATTACH: 'F.TASK.CMP.OPEN_ATTACH',
        OPEN_ISSUE: 'F.TASK.CMP.OPEN_ISSUE',
        OPEN_TIME: 'F.TASK.CMP.OPEN_TIME',
        REMOVE_FROM_MY_DAY: 'F.TASK.CMP.REMOVE_FROM_MY_DAY',
        REPEAT_EDIT: 'F.TASK.CMP.REPEAT_EDIT',
        SCHEDULE: 'F.TASK.CMP.SCHEDULE',
        SHOW_UPDATES: 'F.TASK.CMP.SHOW_UPDATES',
        TOGGLE_ATTACHMENTS: 'F.TASK.CMP.TOGGLE_ATTACHMENTS',
        TOGGLE_DETAIL_PANEL: 'F.TASK.CMP.TOGGLE_DETAIL_PANEL',
        TOGGLE_DONE: 'F.TASK.CMP.TOGGLE_DONE',
        TOGGLE_SUB_TASK_VISIBILITY: 'F.TASK.CMP.TOGGLE_SUB_TASK_VISIBILITY',
        TRACK_TIME: 'F.TASK.CMP.TRACK_TIME',
        TRACK_TIME_STOP: 'F.TASK.CMP.TRACK_TIME_STOP',
        UPDATE_ISSUE_DATA: 'F.TASK.CMP.UPDATE_ISSUE_DATA',
      },
      D_CONFIRM_SHORT_SYNTAX_NEW_TAG: {
        MSG: 'F.TASK.D_CONFIRM_SHORT_SYNTAX_NEW_TAG.MSG',
        OK: 'F.TASK.D_CONFIRM_SHORT_SYNTAX_NEW_TAG.OK',
      },
      D_CONFIRM_SHORT_SYNTAX_NEW_TAGS: {
        MSG: 'F.TASK.D_CONFIRM_SHORT_SYNTAX_NEW_TAGS.MSG',
        OK: 'F.TASK.D_CONFIRM_SHORT_SYNTAX_NEW_TAGS.OK',
      },
      D_REMINDER_VIEW: {
        ADD_ALL_TO_TODAY: 'F.TASK.D_REMINDER_VIEW.ADD_ALL_TO_TODAY',
        ADD_TO_TODAY: 'F.TASK.D_REMINDER_VIEW.ADD_TO_TODAY',
        DISMISS: 'F.TASK.D_REMINDER_VIEW.DISMISS',
        DISMISS_ALL: 'F.TASK.D_REMINDER_VIEW.DISMISS_ALL',
        DONE: 'F.TASK.D_REMINDER_VIEW.DONE',
        DUE_TASK: 'F.TASK.D_REMINDER_VIEW.DUE_TASK',
        DUE_TASKS: 'F.TASK.D_REMINDER_VIEW.DUE_TASKS',
        FOR_CURRENT: 'F.TASK.D_REMINDER_VIEW.FOR_CURRENT',
        FOR_OTHER: 'F.TASK.D_REMINDER_VIEW.FOR_OTHER',
        FROM_PROJECT: 'F.TASK.D_REMINDER_VIEW.FROM_PROJECT',
        FROM_TAG: 'F.TASK.D_REMINDER_VIEW.FROM_TAG',
        RESCHEDULE_EDIT: 'F.TASK.D_REMINDER_VIEW.RESCHEDULE_EDIT',
        RESCHEDULE_UNTIL_TOMORROW: 'F.TASK.D_REMINDER_VIEW.RESCHEDULE_UNTIL_TOMORROW',
        SNOOZE: 'F.TASK.D_REMINDER_VIEW.SNOOZE',
        SNOOZE_ALL: 'F.TASK.D_REMINDER_VIEW.SNOOZE_ALL',
        START: 'F.TASK.D_REMINDER_VIEW.START',
        SWITCH_CONTEXT_START: 'F.TASK.D_REMINDER_VIEW.SWITCH_CONTEXT_START',
      },
      D_SCHEDULE_TASK: {
        MOVE_TO_BACKLOG: 'F.TASK.D_SCHEDULE_TASK.MOVE_TO_BACKLOG',
        QA_NEXT_MONTH: 'F.TASK.D_SCHEDULE_TASK.QA_NEXT_MONTH',
        QA_NEXT_WEEK: 'F.TASK.D_SCHEDULE_TASK.QA_NEXT_WEEK',
        QA_REMOVE_TODAY: 'F.TASK.D_SCHEDULE_TASK.QA_REMOVE_TODAY',
        QA_TODAY: 'F.TASK.D_SCHEDULE_TASK.QA_TODAY',
        QA_TOMORROW: 'F.TASK.D_SCHEDULE_TASK.QA_TOMORROW',
        REMIND_AT: 'F.TASK.D_SCHEDULE_TASK.REMIND_AT',
        RO_1H: 'F.TASK.D_SCHEDULE_TASK.RO_1H',
        RO_5M: 'F.TASK.D_SCHEDULE_TASK.RO_5M',
        RO_10M: 'F.TASK.D_SCHEDULE_TASK.RO_10M',
        RO_15M: 'F.TASK.D_SCHEDULE_TASK.RO_15M',
        RO_30M: 'F.TASK.D_SCHEDULE_TASK.RO_30M',
        RO_NEVER: 'F.TASK.D_SCHEDULE_TASK.RO_NEVER',
        RO_START: 'F.TASK.D_SCHEDULE_TASK.RO_START',
        SCHEDULE: 'F.TASK.D_SCHEDULE_TASK.SCHEDULE',
        UNSCHEDULE: 'F.TASK.D_SCHEDULE_TASK.UNSCHEDULE',
      },
      D_TIME: {
        ADD_FOR_OTHER_DAY: 'F.TASK.D_TIME.ADD_FOR_OTHER_DAY',
        DELETE_FOR: 'F.TASK.D_TIME.DELETE_FOR',
        ESTIMATE: 'F.TASK.D_TIME.ESTIMATE',
        TIME_SPENT: 'F.TASK.D_TIME.TIME_SPENT',
        TIME_SPENT_ON: 'F.TASK.D_TIME.TIME_SPENT_ON',
        TITLE: 'F.TASK.D_TIME.TITLE',
      },
      D_TIME_FOR_DAY: {
        ADD_ENTRY_FOR: 'F.TASK.D_TIME_FOR_DAY.ADD_ENTRY_FOR',
        DATE: 'F.TASK.D_TIME_FOR_DAY.DATE',
        HELP: 'F.TASK.D_TIME_FOR_DAY.HELP',
        TINE_SPENT: 'F.TASK.D_TIME_FOR_DAY.TINE_SPENT',
        TITLE: 'F.TASK.D_TIME_FOR_DAY.TITLE',
      },
      N: {
        ESTIMATE_EXCEEDED: 'F.TASK.N.ESTIMATE_EXCEEDED',
        ESTIMATE_EXCEEDED_BODY: 'F.TASK.N.ESTIMATE_EXCEEDED_BODY',
      },
      S: {
        CANNOT_ASSIGN_PROJECT_FOR_REPEATABLE_TASK:
          'F.TASK.S.CANNOT_ASSIGN_PROJECT_FOR_REPEATABLE_TASK',
        DELETED: 'F.TASK.S.DELETED',
        FOUND_MOVE_FROM_BACKLOG: 'F.TASK.S.FOUND_MOVE_FROM_BACKLOG',
        FOUND_MOVE_FROM_OTHER_LIST: 'F.TASK.S.FOUND_MOVE_FROM_OTHER_LIST',
        FOUND_RESTORE_FROM_ARCHIVE: 'F.TASK.S.FOUND_RESTORE_FROM_ARCHIVE',
        LAST_TAG_DELETION_WARNING: 'F.TASK.S.LAST_TAG_DELETION_WARNING',
        REMINDER_ADDED: 'F.TASK.S.REMINDER_ADDED',
        REMINDER_DELETED: 'F.TASK.S.REMINDER_DELETED',
        REMINDER_UPDATED: 'F.TASK.S.REMINDER_UPDATED',
        TASK_CREATED: 'F.TASK.S.TASK_CREATED',
      },
      SELECT_OR_CREATE: 'F.TASK.SELECT_OR_CREATE',
      SUMMARY_TABLE: {
        ESTIMATE: 'F.TASK.SUMMARY_TABLE.ESTIMATE',
        SPENT_TODAY: 'F.TASK.SUMMARY_TABLE.SPENT_TODAY',
        SPENT_TOTAL: 'F.TASK.SUMMARY_TABLE.SPENT_TOTAL',
        TASK: 'F.TASK.SUMMARY_TABLE.TASK',
        TOGGLE_DONE: 'F.TASK.SUMMARY_TABLE.TOGGLE_DONE',
      },
    },
    TASK_REPEAT: {
      ADD_INFO_PANEL: {
        CUSTOM: 'F.TASK_REPEAT.ADD_INFO_PANEL.CUSTOM',
        CUSTOM_AND_TIME: 'F.TASK_REPEAT.ADD_INFO_PANEL.CUSTOM_AND_TIME',
        CUSTOM_WEEKLY: 'F.TASK_REPEAT.ADD_INFO_PANEL.CUSTOM_WEEKLY',
        CUSTOM_WEEKLY_AND_TIME: 'F.TASK_REPEAT.ADD_INFO_PANEL.CUSTOM_WEEKLY_AND_TIME',
        DAILY: 'F.TASK_REPEAT.ADD_INFO_PANEL.DAILY',
        DAILY_AND_TIME: 'F.TASK_REPEAT.ADD_INFO_PANEL.DAILY_AND_TIME',
        EVERY_X_DAILY: 'F.TASK_REPEAT.ADD_INFO_PANEL.EVERY_X_DAILY',
        EVERY_X_DAILY_AND_TIME: 'F.TASK_REPEAT.ADD_INFO_PANEL.EVERY_X_DAILY_AND_TIME',
        EVERY_X_MONTHLY: 'F.TASK_REPEAT.ADD_INFO_PANEL.EVERY_X_MONTHLY',
        EVERY_X_MONTHLY_AND_TIME: 'F.TASK_REPEAT.ADD_INFO_PANEL.EVERY_X_MONTHLY_AND_TIME',
        EVERY_X_YEARLY: 'F.TASK_REPEAT.ADD_INFO_PANEL.EVERY_X_YEARLY',
        EVERY_X_YEARLY_AND_TIME: 'F.TASK_REPEAT.ADD_INFO_PANEL.EVERY_X_YEARLY_AND_TIME',
        MONDAY_TO_FRIDAY: 'F.TASK_REPEAT.ADD_INFO_PANEL.MONDAY_TO_FRIDAY',
        MONDAY_TO_FRIDAY_AND_TIME:
          'F.TASK_REPEAT.ADD_INFO_PANEL.MONDAY_TO_FRIDAY_AND_TIME',
        MONTHLY_CURRENT_DATE: 'F.TASK_REPEAT.ADD_INFO_PANEL.MONTHLY_CURRENT_DATE',
        MONTHLY_CURRENT_DATE_AND_TIME:
          'F.TASK_REPEAT.ADD_INFO_PANEL.MONTHLY_CURRENT_DATE_AND_TIME',
        WEEKLY_CURRENT_WEEKDAY: 'F.TASK_REPEAT.ADD_INFO_PANEL.WEEKLY_CURRENT_WEEKDAY',
        WEEKLY_CURRENT_WEEKDAY_AND_TIME:
          'F.TASK_REPEAT.ADD_INFO_PANEL.WEEKLY_CURRENT_WEEKDAY_AND_TIME',
        YEARLY_CURRENT_DATE: 'F.TASK_REPEAT.ADD_INFO_PANEL.YEARLY_CURRENT_DATE',
        YEARLY_CURRENT_DATE_AND_TIME:
          'F.TASK_REPEAT.ADD_INFO_PANEL.YEARLY_CURRENT_DATE_AND_TIME',
      },
      D_CONFIRM_MOVE_TO_PROJECT: {
        MSG: 'F.TASK_REPEAT.D_CONFIRM_MOVE_TO_PROJECT.MSG',
        OK: 'F.TASK_REPEAT.D_CONFIRM_MOVE_TO_PROJECT.OK',
      },
      D_CONFIRM_REMOVE: {
        MSG: 'F.TASK_REPEAT.D_CONFIRM_REMOVE.MSG',
        OK: 'F.TASK_REPEAT.D_CONFIRM_REMOVE.OK',
      },
      D_CONFIRM_UPDATE_INSTANCES: {
        CANCEL: 'F.TASK_REPEAT.D_CONFIRM_UPDATE_INSTANCES.CANCEL',
        MSG: 'F.TASK_REPEAT.D_CONFIRM_UPDATE_INSTANCES.MSG',
        OK: 'F.TASK_REPEAT.D_CONFIRM_UPDATE_INSTANCES.OK',
      },
      D_EDIT: {
        ADD: 'F.TASK_REPEAT.D_EDIT.ADD',
        ADVANCED_CFG: 'F.TASK_REPEAT.D_EDIT.ADVANCED_CFG',
        EDIT: 'F.TASK_REPEAT.D_EDIT.EDIT',
        HELP1: 'F.TASK_REPEAT.D_EDIT.HELP1',
        HELP2: 'F.TASK_REPEAT.D_EDIT.HELP2',
        HELP3: 'F.TASK_REPEAT.D_EDIT.HELP3',
        HELP4: 'F.TASK_REPEAT.D_EDIT.HELP4',
        TAG_LABEL: 'F.TASK_REPEAT.D_EDIT.TAG_LABEL',
      },
      F: {
        C_DAY: 'F.TASK_REPEAT.F.C_DAY',
        C_MONTH: 'F.TASK_REPEAT.F.C_MONTH',
        C_WEEK: 'F.TASK_REPEAT.F.C_WEEK',
        C_YEAR: 'F.TASK_REPEAT.F.C_YEAR',
        DEFAULT_ESTIMATE: 'F.TASK_REPEAT.F.DEFAULT_ESTIMATE',
        FRIDAY: 'F.TASK_REPEAT.F.FRIDAY',
        IS_ADD_TO_BOTTOM: 'F.TASK_REPEAT.F.IS_ADD_TO_BOTTOM',
        MONDAY: 'F.TASK_REPEAT.F.MONDAY',
        NOTES: 'F.TASK_REPEAT.F.NOTES',
        ORDER: 'F.TASK_REPEAT.F.ORDER',
        ORDER_DESCRIPTION: 'F.TASK_REPEAT.F.ORDER_DESCRIPTION',
        Q_CUSTOM: 'F.TASK_REPEAT.F.Q_CUSTOM',
        Q_DAILY: 'F.TASK_REPEAT.F.Q_DAILY',
        Q_MONDAY_TO_FRIDAY: 'F.TASK_REPEAT.F.Q_MONDAY_TO_FRIDAY',
        Q_MONTHLY_CURRENT_DATE: 'F.TASK_REPEAT.F.Q_MONTHLY_CURRENT_DATE',
        Q_WEEKLY_CURRENT_WEEKDAY: 'F.TASK_REPEAT.F.Q_WEEKLY_CURRENT_WEEKDAY',
        Q_YEARLY_CURRENT_DATE: 'F.TASK_REPEAT.F.Q_YEARLY_CURRENT_DATE',
        QUICK_SETTING: 'F.TASK_REPEAT.F.QUICK_SETTING',
        REMIND_AT: 'F.TASK_REPEAT.F.REMIND_AT',
        REMIND_AT_PLACEHOLDER: 'F.TASK_REPEAT.F.REMIND_AT_PLACEHOLDER',
        REPEAT_CYCLE: 'F.TASK_REPEAT.F.REPEAT_CYCLE',
        REPEAT_EVERY: 'F.TASK_REPEAT.F.REPEAT_EVERY',
        SATURDAY: 'F.TASK_REPEAT.F.SATURDAY',
        START_DATE: 'F.TASK_REPEAT.F.START_DATE',
        START_TIME: 'F.TASK_REPEAT.F.START_TIME',
        START_TIME_DESCRIPTION: 'F.TASK_REPEAT.F.START_TIME_DESCRIPTION',
        SUNDAY: 'F.TASK_REPEAT.F.SUNDAY',
        THURSDAY: 'F.TASK_REPEAT.F.THURSDAY',
        TITLE: 'F.TASK_REPEAT.F.TITLE',
        TUESDAY: 'F.TASK_REPEAT.F.TUESDAY',
        WEDNESDAY: 'F.TASK_REPEAT.F.WEDNESDAY',
      },
    },
    TIME_TRACKING: {
      B: {
        ALREADY_DID: 'F.TIME_TRACKING.B.ALREADY_DID',
        SNOOZE: 'F.TIME_TRACKING.B.SNOOZE',
      },
      B_TTR: {
        ADD_TO_TASK: 'F.TIME_TRACKING.B_TTR.ADD_TO_TASK',
        MSG: 'F.TIME_TRACKING.B_TTR.MSG',
      },
      D_IDLE: {
        ADD_ENTRY: 'F.TIME_TRACKING.D_IDLE.ADD_ENTRY',
        BREAK: 'F.TIME_TRACKING.D_IDLE.BREAK',
        CREATE_AND_TRACK: 'F.TIME_TRACKING.D_IDLE.CREATE_AND_TRACK',
        IDLE_FOR: 'F.TIME_TRACKING.D_IDLE.IDLE_FOR',
        RESET_BREAK_REMINDER_TIMER: 'F.TIME_TRACKING.D_IDLE.RESET_BREAK_REMINDER_TIMER',
        SIMPLE_CONFIRM_COUNTER_CANCEL:
          'F.TIME_TRACKING.D_IDLE.SIMPLE_CONFIRM_COUNTER_CANCEL',
        SIMPLE_CONFIRM_COUNTER_OK: 'F.TIME_TRACKING.D_IDLE.SIMPLE_CONFIRM_COUNTER_OK',
        SIMPLE_COUNTER_CONFIRM_TXT: 'F.TIME_TRACKING.D_IDLE.SIMPLE_COUNTER_CONFIRM_TXT',
        SIMPLE_COUNTER_TOOLTIP: 'F.TIME_TRACKING.D_IDLE.SIMPLE_COUNTER_TOOLTIP',
        SIMPLE_COUNTER_TOOLTIP_DISABLE:
          'F.TIME_TRACKING.D_IDLE.SIMPLE_COUNTER_TOOLTIP_DISABLE',
        SKIP: 'F.TIME_TRACKING.D_IDLE.SKIP',
        SPLIT_TIME: 'F.TIME_TRACKING.D_IDLE.SPLIT_TIME',
        TASK: 'F.TIME_TRACKING.D_IDLE.TASK',
        TRACK_TO: 'F.TIME_TRACKING.D_IDLE.TRACK_TO',
      },
      D_TRACKING_REMINDER: {
        CREATE_AND_TRACK: 'F.TIME_TRACKING.D_TRACKING_REMINDER.CREATE_AND_TRACK',
        IDLE_FOR: 'F.TIME_TRACKING.D_TRACKING_REMINDER.IDLE_FOR',
        TASK: 'F.TIME_TRACKING.D_TRACKING_REMINDER.TASK',
        TRACK_TO: 'F.TIME_TRACKING.D_TRACKING_REMINDER.TRACK_TO',
        UNTRACKED_TIME: 'F.TIME_TRACKING.D_TRACKING_REMINDER.UNTRACKED_TIME',
      },
    },
    WORKLOG: {
      CMP: {
        DAYS_WORKED: 'F.WORKLOG.CMP.DAYS_WORKED',
        MONTH_WORKED: 'F.WORKLOG.CMP.MONTH_WORKED',
        REPEATING_TASK: 'F.WORKLOG.CMP.REPEATING_TASK',
        RESTORE_TASK_FROM_ARCHIVE: 'F.WORKLOG.CMP.RESTORE_TASK_FROM_ARCHIVE',
        TASKS: 'F.WORKLOG.CMP.TASKS',
        TOTAL_TIME: 'F.WORKLOG.CMP.TOTAL_TIME',
        WEEK_NR: 'F.WORKLOG.CMP.WEEK_NR',
        WORKED: 'F.WORKLOG.CMP.WORKED',
      },
      D_CONFIRM_RESTORE: 'F.WORKLOG.D_CONFIRM_RESTORE',
      D_EXPORT_TITLE: 'F.WORKLOG.D_EXPORT_TITLE',
      D_EXPORT_TITLE_SINGLE: 'F.WORKLOG.D_EXPORT_TITLE_SINGLE',
      EXPORT: {
        ADD_COL: 'F.WORKLOG.EXPORT.ADD_COL',
        COPY_TO_CLIPBOARD: 'F.WORKLOG.EXPORT.COPY_TO_CLIPBOARD',
        DONT_ROUND: 'F.WORKLOG.EXPORT.DONT_ROUND',
        EDIT_COL: 'F.WORKLOG.EXPORT.EDIT_COL',
        GROUP_BY: 'F.WORKLOG.EXPORT.GROUP_BY',
        O: {
          DATE: 'F.WORKLOG.EXPORT.O.DATE',
          ENDED_WORKING: 'F.WORKLOG.EXPORT.O.ENDED_WORKING',
          ESTIMATE_AS_CLOCK: 'F.WORKLOG.EXPORT.O.ESTIMATE_AS_CLOCK',
          ESTIMATE_AS_MILLISECONDS: 'F.WORKLOG.EXPORT.O.ESTIMATE_AS_MILLISECONDS',
          ESTIMATE_AS_STRING: 'F.WORKLOG.EXPORT.O.ESTIMATE_AS_STRING',
          FULL_HALF_HOURS: 'F.WORKLOG.EXPORT.O.FULL_HALF_HOURS',
          FULL_HOURS: 'F.WORKLOG.EXPORT.O.FULL_HOURS',
          FULL_QUARTERS: 'F.WORKLOG.EXPORT.O.FULL_QUARTERS',
          NOTES: 'F.WORKLOG.EXPORT.O.NOTES',
          PARENT_TASK: 'F.WORKLOG.EXPORT.O.PARENT_TASK',
          PARENT_TASK_TITLES_ONLY: 'F.WORKLOG.EXPORT.O.PARENT_TASK_TITLES_ONLY',
          PROJECTS: 'F.WORKLOG.EXPORT.O.PROJECTS',
          STARTED_WORKING: 'F.WORKLOG.EXPORT.O.STARTED_WORKING',
          TAGS: 'F.WORKLOG.EXPORT.O.TAGS',
          TASK_SUBTASK: 'F.WORKLOG.EXPORT.O.TASK_SUBTASK',
          TIME_AS_CLOCK: 'F.WORKLOG.EXPORT.O.TIME_AS_CLOCK',
          TIME_AS_MILLISECONDS: 'F.WORKLOG.EXPORT.O.TIME_AS_MILLISECONDS',
          TIME_AS_STRING: 'F.WORKLOG.EXPORT.O.TIME_AS_STRING',
          TITLES_AND_SUB_TASK_TITLES: 'F.WORKLOG.EXPORT.O.TITLES_AND_SUB_TASK_TITLES',
          WORKLOG: 'F.WORKLOG.EXPORT.O.WORKLOG',
        },
        OPTIONS: 'F.WORKLOG.EXPORT.OPTIONS',
        ROUND_END_TIME_TO: 'F.WORKLOG.EXPORT.ROUND_END_TIME_TO',
        ROUND_START_TIME_TO: 'F.WORKLOG.EXPORT.ROUND_START_TIME_TO',
        ROUND_TIME_WORKED_TO: 'F.WORKLOG.EXPORT.ROUND_TIME_WORKED_TO',
        SAVE_TO_FILE: 'F.WORKLOG.EXPORT.SAVE_TO_FILE',
        SEPARATE_TASKS_BY: 'F.WORKLOG.EXPORT.SEPARATE_TASKS_BY',
        SHOW_AS_TEXT: 'F.WORKLOG.EXPORT.SHOW_AS_TEXT',
      },
      WEEK: {
        EXPORT: 'F.WORKLOG.WEEK.EXPORT',
        NO_DATA: 'F.WORKLOG.WEEK.NO_DATA',
        TITLE: 'F.WORKLOG.WEEK.TITLE',
      },
    },
  },
  FILE_IMEX: {
    EXPORT_DATA: 'FILE_IMEX.EXPORT_DATA',
    IMPORT_FROM_FILE: 'FILE_IMEX.IMPORT_FROM_FILE',
    PRIVACY_EXPORT: 'FILE_IMEX.PRIVACY_EXPORT',
    S_ERR_INVALID_DATA: 'FILE_IMEX.S_ERR_INVALID_DATA',
  },
  G: {
    ADD: 'G.ADD',
    CANCEL: 'G.CANCEL',
    CLOSE: 'G.CLOSE',
    DELETE: 'G.DELETE',
    DISMISS: 'G.DISMISS',
    DO_IT: 'G.DO_IT',
    DURATION_DESCRIPTION: 'G.DURATION_DESCRIPTION',
    EDIT: 'G.EDIT',
    ENABLED: 'G.ENABLED',
    EXAMPLE_VAL: 'G.EXAMPLE_VAL',
    EXTENSION_INFO: 'G.EXTENSION_INFO',
    HIDE: 'G.HIDE',
    ICON_INP_DESCRIPTION: 'G.ICON_INP_DESCRIPTION',
    LOGIN: 'G.LOGIN',
    LOGOUT: 'G.LOGOUT',
    MINUTES: 'G.MINUTES',
    NEXT: 'G.NEXT',
    NO_CON: 'G.NO_CON',
    NONE: 'G.NONE',
    OK: 'G.OK',
    PREVIOUS: 'G.PREVIOUS',
    REMOVE: 'G.REMOVE',
    RESET: 'G.RESET',
    SAVE: 'G.SAVE',
    SUBMIT: 'G.SUBMIT',
    TITLE: 'G.TITLE',
    TODAY_TAG_TITLE: 'G.TODAY_TAG_TITLE',
    TRACKING_INTERVAL_DESCRIPTION: 'G.TRACKING_INTERVAL_DESCRIPTION',
    UNDO: 'G.UNDO',
    UPDATE: 'G.UPDATE',
    WITHOUT_PROJECT: 'G.WITHOUT_PROJECT',
    YESTERDAY: 'G.YESTERDAY',
  },
  GCF: {
    AUTO_BACKUPS: {
      HELP: 'GCF.AUTO_BACKUPS.HELP',
      LABEL_IS_ENABLED: 'GCF.AUTO_BACKUPS.LABEL_IS_ENABLED',
      LOCATION_INFO: 'GCF.AUTO_BACKUPS.LOCATION_INFO',
      TITLE: 'GCF.AUTO_BACKUPS.TITLE',
    },
    CALENDARS: {
      BROWSER_WARNING: 'GCF.CALENDARS.BROWSER_WARNING',
      CAL_PATH: 'GCF.CALENDARS.CAL_PATH',
      CAL_PROVIDERS: 'GCF.CALENDARS.CAL_PROVIDERS',
      CHECK_UPDATES: 'GCF.CALENDARS.CHECK_UPDATES',
      DEFAULT_PROJECT: 'GCF.CALENDARS.DEFAULT_PROJECT',
      HELP: 'GCF.CALENDARS.HELP',
      SHOW_BANNER_THRESHOLD: 'GCF.CALENDARS.SHOW_BANNER_THRESHOLD',
    },
    EVALUATION: {
      IS_HIDE_EVALUATION_SHEET: 'GCF.EVALUATION.IS_HIDE_EVALUATION_SHEET',
      TITLE: 'GCF.EVALUATION.TITLE',
    },
    FOCUS_MODE: {
      HELP: 'GCF.FOCUS_MODE.HELP',
      L_ALWAYS_OPEN_FOCUS_MODE: 'GCF.FOCUS_MODE.L_ALWAYS_OPEN_FOCUS_MODE',
      L_SKIP_PREPARATION_SCREEN: 'GCF.FOCUS_MODE.L_SKIP_PREPARATION_SCREEN',
      TITLE: 'GCF.FOCUS_MODE.TITLE',
    },
    IDLE: {
      HELP: 'GCF.IDLE.HELP',
      IS_ENABLE_IDLE_TIME_TRACKING: 'GCF.IDLE.IS_ENABLE_IDLE_TIME_TRACKING',
      IS_ONLY_OPEN_IDLE_WHEN_CURRENT_TASK: 'GCF.IDLE.IS_ONLY_OPEN_IDLE_WHEN_CURRENT_TASK',
      MIN_IDLE_TIME: 'GCF.IDLE.MIN_IDLE_TIME',
      TITLE: 'GCF.IDLE.TITLE',
    },
    IMEX: {
      HELP: 'GCF.IMEX.HELP',
      TITLE: 'GCF.IMEX.TITLE',
    },
    KEYBOARD: {
      ADD_NEW_NOTE: 'GCF.KEYBOARD.ADD_NEW_NOTE',
      ADD_NEW_TASK: 'GCF.KEYBOARD.ADD_NEW_TASK',
      APP_WIDE_SHORTCUTS: 'GCF.KEYBOARD.APP_WIDE_SHORTCUTS',
      COLLAPSE_SUB_TASKS: 'GCF.KEYBOARD.COLLAPSE_SUB_TASKS',
      EXPAND_SUB_TASKS: 'GCF.KEYBOARD.EXPAND_SUB_TASKS',
      GLOBAL_ADD_NOTE: 'GCF.KEYBOARD.GLOBAL_ADD_NOTE',
      GLOBAL_ADD_TASK: 'GCF.KEYBOARD.GLOBAL_ADD_TASK',
      GLOBAL_SHOW_HIDE: 'GCF.KEYBOARD.GLOBAL_SHOW_HIDE',
      GLOBAL_TOGGLE_TASK_START: 'GCF.KEYBOARD.GLOBAL_TOGGLE_TASK_START',
      GO_TO_DAILY_AGENDA: 'GCF.KEYBOARD.GO_TO_DAILY_AGENDA',
      GO_TO_FOCUS_MODE: 'GCF.KEYBOARD.GO_TO_FOCUS_MODE',
      GO_TO_SCHEDULE: 'GCF.KEYBOARD.GO_TO_SCHEDULE',
      GO_TO_SCHEDULED_VIEW: 'GCF.KEYBOARD.GO_TO_SCHEDULED_VIEW',
      GO_TO_SETTINGS: 'GCF.KEYBOARD.GO_TO_SETTINGS',
      GO_TO_WORK_VIEW: 'GCF.KEYBOARD.GO_TO_WORK_VIEW',
      HELP: 'GCF.KEYBOARD.HELP',
      MOVE_TASK_DOWN: 'GCF.KEYBOARD.MOVE_TASK_DOWN',
      MOVE_TASK_TO_BOTTOM: 'GCF.KEYBOARD.MOVE_TASK_TO_BOTTOM',
      MOVE_TASK_TO_TOP: 'GCF.KEYBOARD.MOVE_TASK_TO_TOP',
      MOVE_TASK_UP: 'GCF.KEYBOARD.MOVE_TASK_UP',
      MOVE_TO_BACKLOG: 'GCF.KEYBOARD.MOVE_TO_BACKLOG',
      MOVE_TO_TODAYS_TASKS: 'GCF.KEYBOARD.MOVE_TO_TODAYS_TASKS',
      OPEN_PROJECT_NOTES: 'GCF.KEYBOARD.OPEN_PROJECT_NOTES',
      SAVE_NOTE: 'GCF.KEYBOARD.SAVE_NOTE',
      SELECT_NEXT_TASK: 'GCF.KEYBOARD.SELECT_NEXT_TASK',
      SELECT_PREVIOUS_TASK: 'GCF.KEYBOARD.SELECT_PREVIOUS_TASK',
      SHOW_SEARCH_BAR: 'GCF.KEYBOARD.SHOW_SEARCH_BAR',
      SYSTEM_SHORTCUTS: 'GCF.KEYBOARD.SYSTEM_SHORTCUTS',
      TASK_ADD_ATTACHMENT: 'GCF.KEYBOARD.TASK_ADD_ATTACHMENT',
      TASK_ADD_SUB_TASK: 'GCF.KEYBOARD.TASK_ADD_SUB_TASK',
      TASK_DELETE: 'GCF.KEYBOARD.TASK_DELETE',
      TASK_EDIT_TAGS: 'GCF.KEYBOARD.TASK_EDIT_TAGS',
      TASK_EDIT_TITLE: 'GCF.KEYBOARD.TASK_EDIT_TITLE',
      TASK_MOVE_TO_PROJECT: 'GCF.KEYBOARD.TASK_MOVE_TO_PROJECT',
      TASK_OPEN_CONTEXT_MENU: 'GCF.KEYBOARD.TASK_OPEN_CONTEXT_MENU',
      TASK_OPEN_ESTIMATION_DIALOG: 'GCF.KEYBOARD.TASK_OPEN_ESTIMATION_DIALOG',
      TASK_PLAN_FORDAY: 'GCF.KEYBOARD.TASK_PLAN_FORDAY',
      TASK_SCHEDULE: 'GCF.KEYBOARD.TASK_SCHEDULE',
      TASK_SHORTCUTS: 'GCF.KEYBOARD.TASK_SHORTCUTS',
      TASK_SHORTCUTS_INFO: 'GCF.KEYBOARD.TASK_SHORTCUTS_INFO',
      TASK_TOGGLE_DETAIL_PANEL_OPEN: 'GCF.KEYBOARD.TASK_TOGGLE_DETAIL_PANEL_OPEN',
      TASK_TOGGLE_DONE: 'GCF.KEYBOARD.TASK_TOGGLE_DONE',
      TITLE: 'GCF.KEYBOARD.TITLE',
      TOGGLE_BACKLOG: 'GCF.KEYBOARD.TOGGLE_BACKLOG',
      TOGGLE_BOOKMARKS: 'GCF.KEYBOARD.TOGGLE_BOOKMARKS',
      TOGGLE_ISSUE_PANEL: 'GCF.KEYBOARD.TOGGLE_ISSUE_PANEL',
      TOGGLE_PLAY: 'GCF.KEYBOARD.TOGGLE_PLAY',
      TOGGLE_SIDE_NAV: 'GCF.KEYBOARD.TOGGLE_SIDE_NAV',
      TRIGGER_SYNC: 'GCF.KEYBOARD.TRIGGER_SYNC',
      ZOOM_DEFAULT: 'GCF.KEYBOARD.ZOOM_DEFAULT',
      ZOOM_IN: 'GCF.KEYBOARD.ZOOM_IN',
      ZOOM_OUT: 'GCF.KEYBOARD.ZOOM_OUT',
    },
    LANG: {
      AR: 'GCF.LANG.AR',
      CZ: 'GCF.LANG.CZ',
      DE: 'GCF.LANG.DE',
      EN: 'GCF.LANG.EN',
      ES: 'GCF.LANG.ES',
      FA: 'GCF.LANG.FA',
      FR: 'GCF.LANG.FR',
      HR: 'GCF.LANG.HR',
      ID: 'GCF.LANG.ID',
      IT: 'GCF.LANG.IT',
      JA: 'GCF.LANG.JA',
      KO: 'GCF.LANG.KO',
      LABEL: 'GCF.LANG.LABEL',
      NB: 'GCF.LANG.NB',
      NL: 'GCF.LANG.NL',
      PL: 'GCF.LANG.PL',
      PT: 'GCF.LANG.PT',
      RU: 'GCF.LANG.RU',
      SK: 'GCF.LANG.SK',
      TITLE: 'GCF.LANG.TITLE',
      TR: 'GCF.LANG.TR',
      UK: 'GCF.LANG.UK',
      ZH: 'GCF.LANG.ZH',
      ZH_TW: 'GCF.LANG.ZH_TW',
    },
    MISC: {
      DEFAULT_PROJECT: 'GCF.MISC.DEFAULT_PROJECT',
      FIRST_DAY_OF_WEEK: 'GCF.MISC.FIRST_DAY_OF_WEEK',
      HELP: 'GCF.MISC.HELP',
      IS_AUTO_ADD_WORKED_ON_TO_TODAY: 'GCF.MISC.IS_AUTO_ADD_WORKED_ON_TO_TODAY',
      IS_AUTO_MARK_PARENT_AS_DONE: 'GCF.MISC.IS_AUTO_MARK_PARENT_AS_DONE',
      IS_CONFIRM_BEFORE_EXIT: 'GCF.MISC.IS_CONFIRM_BEFORE_EXIT',
      IS_CONFIRM_BEFORE_EXIT_WITHOUT_FINISH_DAY:
        'GCF.MISC.IS_CONFIRM_BEFORE_EXIT_WITHOUT_FINISH_DAY',
      IS_DARK_MODE: 'GCF.MISC.IS_DARK_MODE',
      IS_DISABLE_ANIMATIONS: 'GCF.MISC.IS_DISABLE_ANIMATIONS',
      IS_HIDE_NAV: 'GCF.MISC.IS_HIDE_NAV',
      IS_MINIMIZE_TO_TRAY: 'GCF.MISC.IS_MINIMIZE_TO_TRAY',
      IS_TRAY_SHOW_CURRENT_TASK: 'GCF.MISC.IS_TRAY_SHOW_CURRENT_TASK',
      IS_TURN_OFF_MARKDOWN: 'GCF.MISC.IS_TURN_OFF_MARKDOWN',
      IS_USE_MINIMAL_SIDE_NAV: 'GCF.MISC.IS_USE_MINIMAL_SIDE_NAV',
      START_OF_NEXT_DAY: 'GCF.MISC.START_OF_NEXT_DAY',
      START_OF_NEXT_DAY_HINT: 'GCF.MISC.START_OF_NEXT_DAY_HINT',
      TASK_NOTES_TPL: 'GCF.MISC.TASK_NOTES_TPL',
      TITLE: 'GCF.MISC.TITLE',
    },
    POMODORO: {
      BREAK_DURATION: 'GCF.POMODORO.BREAK_DURATION',
      CYCLES_BEFORE_LONGER_BREAK: 'GCF.POMODORO.CYCLES_BEFORE_LONGER_BREAK',
      DURATION: 'GCF.POMODORO.DURATION',
      HELP: 'GCF.POMODORO.HELP',
      IS_ENABLED: 'GCF.POMODORO.IS_ENABLED',
      IS_MANUAL_CONTINUE: 'GCF.POMODORO.IS_MANUAL_CONTINUE',
      IS_MANUAL_CONTINUE_BREAK: 'GCF.POMODORO.IS_MANUAL_CONTINUE_BREAK',
      IS_PLAY_SOUND: 'GCF.POMODORO.IS_PLAY_SOUND',
      IS_PLAY_SOUND_AFTER_BREAK: 'GCF.POMODORO.IS_PLAY_SOUND_AFTER_BREAK',
      IS_PLAY_TICK: 'GCF.POMODORO.IS_PLAY_TICK',
      IS_STOP_TRACKING_ON_BREAK: 'GCF.POMODORO.IS_STOP_TRACKING_ON_BREAK',
      LONGER_BREAK_DURATION: 'GCF.POMODORO.LONGER_BREAK_DURATION',
      TITLE: 'GCF.POMODORO.TITLE',
    },
    REMINDER: {
      COUNTDOWN_DURATION: 'GCF.REMINDER.COUNTDOWN_DURATION',
      IS_COUNTDOWN_BANNER_ENABLED: 'GCF.REMINDER.IS_COUNTDOWN_BANNER_ENABLED',
      TITLE: 'GCF.REMINDER.TITLE',
    },
    SCHEDULE: {
      HELP: 'GCF.SCHEDULE.HELP',
      L_IS_LUNCH_BREAK_ENABLED: 'GCF.SCHEDULE.L_IS_LUNCH_BREAK_ENABLED',
      L_IS_WORK_START_END_ENABLED: 'GCF.SCHEDULE.L_IS_WORK_START_END_ENABLED',
      L_LUNCH_BREAK_END: 'GCF.SCHEDULE.L_LUNCH_BREAK_END',
      L_LUNCH_BREAK_START: 'GCF.SCHEDULE.L_LUNCH_BREAK_START',
      L_WORK_END: 'GCF.SCHEDULE.L_WORK_END',
      L_WORK_START: 'GCF.SCHEDULE.L_WORK_START',
      LUNCH_BREAK_START_END_DESCRIPTION: 'GCF.SCHEDULE.LUNCH_BREAK_START_END_DESCRIPTION',
      TITLE: 'GCF.SCHEDULE.TITLE',
      WORK_START_END_DESCRIPTION: 'GCF.SCHEDULE.WORK_START_END_DESCRIPTION',
    },
    SHORT_SYNTAX: {
      HELP: 'GCF.SHORT_SYNTAX.HELP',
      IS_ENABLE_DUE: 'GCF.SHORT_SYNTAX.IS_ENABLE_DUE',
      IS_ENABLE_PROJECT: 'GCF.SHORT_SYNTAX.IS_ENABLE_PROJECT',
      IS_ENABLE_TAG: 'GCF.SHORT_SYNTAX.IS_ENABLE_TAG',
      TITLE: 'GCF.SHORT_SYNTAX.TITLE',
    },
    SOUND: {
      BREAK_REMINDER_SOUND: 'GCF.SOUND.BREAK_REMINDER_SOUND',
      DONE_SOUND: 'GCF.SOUND.DONE_SOUND',
      IS_INCREASE_DONE_PITCH: 'GCF.SOUND.IS_INCREASE_DONE_PITCH',
      TITLE: 'GCF.SOUND.TITLE',
      VOLUME: 'GCF.SOUND.VOLUME',
    },
    TAKE_A_BREAK: {
      ADD_NEW_IMG: 'GCF.TAKE_A_BREAK.ADD_NEW_IMG',
      FULL_SCREEN_BLOCKER_DURATION: 'GCF.TAKE_A_BREAK.FULL_SCREEN_BLOCKER_DURATION',
      HELP: 'GCF.TAKE_A_BREAK.HELP',
      IS_ENABLED: 'GCF.TAKE_A_BREAK.IS_ENABLED',
      IS_FOCUS_WINDOW: 'GCF.TAKE_A_BREAK.IS_FOCUS_WINDOW',
      IS_FULL_SCREEN_BLOCKER: 'GCF.TAKE_A_BREAK.IS_FULL_SCREEN_BLOCKER',
      IS_LOCK_SCREEN: 'GCF.TAKE_A_BREAK.IS_LOCK_SCREEN',
      MESSAGE: 'GCF.TAKE_A_BREAK.MESSAGE',
      MIN_WORKING_TIME: 'GCF.TAKE_A_BREAK.MIN_WORKING_TIME',
      MOTIVATIONAL_IMGS: 'GCF.TAKE_A_BREAK.MOTIVATIONAL_IMGS',
      NOTIFICATION_TITLE: 'GCF.TAKE_A_BREAK.NOTIFICATION_TITLE',
      SNOOZE_TIME: 'GCF.TAKE_A_BREAK.SNOOZE_TIME',
      TITLE: 'GCF.TAKE_A_BREAK.TITLE',
    },
    TIME_TRACKING: {
      HELP: 'GCF.TIME_TRACKING.HELP',
      L_DEFAULT_ESTIMATE: 'GCF.TIME_TRACKING.L_DEFAULT_ESTIMATE',
      L_DEFAULT_ESTIMATE_SUB_TASKS: 'GCF.TIME_TRACKING.L_DEFAULT_ESTIMATE_SUB_TASKS',
      L_IS_AUTO_START_NEXT_TASK: 'GCF.TIME_TRACKING.L_IS_AUTO_START_NEXT_TASK',
      L_IS_NOTIFY_WHEN_TIME_ESTIMATE_EXCEEDED:
        'GCF.TIME_TRACKING.L_IS_NOTIFY_WHEN_TIME_ESTIMATE_EXCEEDED',
      L_IS_TRACKING_REMINDER_ENABLED: 'GCF.TIME_TRACKING.L_IS_TRACKING_REMINDER_ENABLED',
      L_IS_TRACKING_REMINDER_SHOW_ON_MOBILE:
        'GCF.TIME_TRACKING.L_IS_TRACKING_REMINDER_SHOW_ON_MOBILE',
      L_TRACKING_INTERVAL: 'GCF.TIME_TRACKING.L_TRACKING_INTERVAL',
      L_TRACKING_REMINDER_MIN_TIME: 'GCF.TIME_TRACKING.L_TRACKING_REMINDER_MIN_TIME',
      TITLE: 'GCF.TIME_TRACKING.TITLE',
    },
  },
  GLOBAL_SNACK: {
    COPY_TO_CLIPPBOARD: 'GLOBAL_SNACK.COPY_TO_CLIPPBOARD',
    ERR_COMPRESSION: 'GLOBAL_SNACK.ERR_COMPRESSION',
    FILE_DOWNLOADED: 'GLOBAL_SNACK.FILE_DOWNLOADED',
    FILE_DOWNLOADED_BTN: 'GLOBAL_SNACK.FILE_DOWNLOADED_BTN',
    NAVIGATE_TO_TASK_ERR: 'GLOBAL_SNACK.NAVIGATE_TO_TASK_ERR',
    PERSISTENCE_DISALLOWED: 'GLOBAL_SNACK.PERSISTENCE_DISALLOWED',
    PERSISTENCE_ERROR: 'GLOBAL_SNACK.PERSISTENCE_ERROR',
    RUNNING_X: 'GLOBAL_SNACK.RUNNING_X',
    SHORTCUT_WARN_OPEN_BOOKMARKS_FROM_TAG:
      'GLOBAL_SNACK.SHORTCUT_WARN_OPEN_BOOKMARKS_FROM_TAG',
  },
  GPB: {
    ASSETS: 'GPB.ASSETS',
    DBX_DOWNLOAD: 'GPB.DBX_DOWNLOAD',
    DBX_GEN_TOKEN: 'GPB.DBX_GEN_TOKEN',
    DBX_META: 'GPB.DBX_META',
    DBX_UPLOAD: 'GPB.DBX_UPLOAD',
    GITHUB_LOAD_ISSUE: 'GPB.GITHUB_LOAD_ISSUE',
    JIRA_LOAD_ISSUE: 'GPB.JIRA_LOAD_ISSUE',
    SYNC: 'GPB.SYNC',
    UNKNOWN: 'GPB.UNKNOWN',
    WEB_DAV_DOWNLOAD: 'GPB.WEB_DAV_DOWNLOAD',
    WEB_DAV_UPLOAD: 'GPB.WEB_DAV_UPLOAD',
  },
  MH: {
    ADD_NEW_TASK: 'MH.ADD_NEW_TASK',
    CREATE_PROJECT: 'MH.CREATE_PROJECT',
    CREATE_TAG: 'MH.CREATE_TAG',
    DELETE_PROJECT: 'MH.DELETE_PROJECT',
    DELETE_TAG: 'MH.DELETE_TAG',
    GO_TO_TASK_LIST: 'MH.GO_TO_TASK_LIST',
    HELP: 'MH.HELP',
    HM: {
      CALENDARS: 'MH.HM.CALENDARS',
      CONTRIBUTE: 'MH.HM.CONTRIBUTE',
      GET_HELP_ONLINE: 'MH.HM.GET_HELP_ONLINE',
      KEYBOARD: 'MH.HM.KEYBOARD',
      REDDIT_COMMUNITY: 'MH.HM.REDDIT_COMMUNITY',
      REPORT_A_PROBLEM: 'MH.HM.REPORT_A_PROBLEM',
      START_WELCOME: 'MH.HM.START_WELCOME',
      SYNC: 'MH.HM.SYNC',
    },
    METRICS: 'MH.METRICS',
    NO_TAG_INFO: 'MH.NO_TAG_INFO',
    NOTES: 'MH.NOTES',
    NOTES_PANEL_INFO: 'MH.NOTES_PANEL_INFO',
    PLANNER: 'MH.PLANNER',
    PROCRASTINATE: 'MH.PROCRASTINATE',
    PROJECT_MENU: 'MH.PROJECT_MENU',
    PROJECT_SETTINGS: 'MH.PROJECT_SETTINGS',
    PROJECTS: 'MH.PROJECTS',
    QUICK_HISTORY: 'MH.QUICK_HISTORY',
    REPEAT_SCHEDULED: 'MH.REPEAT_SCHEDULED',
    SCHEDULE: 'MH.SCHEDULE',
    SETTINGS: 'MH.SETTINGS',
    SHOW_SEARCH_BAR: 'MH.SHOW_SEARCH_BAR',
    TAGS: 'MH.TAGS',
    TASK_LIST: 'MH.TASK_LIST',
    TASKS: 'MH.TASKS',
    TOGGLE_SHOW_BOOKMARKS: 'MH.TOGGLE_SHOW_BOOKMARKS',
    TOGGLE_SHOW_ISSUE_PANEL: 'MH.TOGGLE_SHOW_ISSUE_PANEL',
    TOGGLE_SHOW_NOTES: 'MH.TOGGLE_SHOW_NOTES',
    TOGGLE_TRACK_TIME: 'MH.TOGGLE_TRACK_TIME',
    TRIGGER_SYNC: 'MH.TRIGGER_SYNC',
    WORKLOG: 'MH.WORKLOG',
  },
  PDS: {
    BACK: 'PDS.BACK',
    BREAK_LABEL: 'PDS.BREAK_LABEL',
    CELEBRATE: 'PDS.CELEBRATE',
    CLEAR_ALL_CONTINUE: 'PDS.CLEAR_ALL_CONTINUE',
    D_CONFIRM_APP_CLOSE: {
      CANCEL: 'PDS.D_CONFIRM_APP_CLOSE.CANCEL',
      MSG: 'PDS.D_CONFIRM_APP_CLOSE.MSG',
      OK: 'PDS.D_CONFIRM_APP_CLOSE.OK',
    },
    ESTIMATE_TOTAL: 'PDS.ESTIMATE_TOTAL',
    EVALUATE_DAY: 'PDS.EVALUATE_DAY',
    EXPORT_TASK_LIST: 'PDS.EXPORT_TASK_LIST',
    NO_TASKS: 'PDS.NO_TASKS',
    PLAN_TOMORROW: 'PDS.PLAN_TOMORROW',
    REVIEW_TASKS: 'PDS.REVIEW_TASKS',
    ROUND_5M: 'PDS.ROUND_5M',
    ROUND_15M: 'PDS.ROUND_15M',
    ROUND_30M: 'PDS.ROUND_30M',
    ROUND_TIME_SPENT: 'PDS.ROUND_TIME_SPENT',
    ROUND_TIME_SPENT_TITLE: 'PDS.ROUND_TIME_SPENT_TITLE',
    ROUND_TIME_WARNING: 'PDS.ROUND_TIME_WARNING',
    ROUND_UP_5M: 'PDS.ROUND_UP_5M',
    ROUND_UP_15M: 'PDS.ROUND_UP_15M',
    ROUND_UP_30M: 'PDS.ROUND_UP_30M',
    SAVE_AND_GO_HOME: 'PDS.SAVE_AND_GO_HOME',
    SAVE_AND_GO_HOME_TOOLTIP: 'PDS.SAVE_AND_GO_HOME_TOOLTIP',
    START_END: 'PDS.START_END',
    SUMMARY_FOR: 'PDS.SUMMARY_FOR',
    TASKS_COMPLETED: 'PDS.TASKS_COMPLETED',
    TIME_SPENT_AND_ESTIMATE_LABEL: 'PDS.TIME_SPENT_AND_ESTIMATE_LABEL',
    TIME_SPENT_ESTIMATE_TITLE: 'PDS.TIME_SPENT_ESTIMATE_TITLE',
    TIME_SPENT_TODAY_BY_TAG: 'PDS.TIME_SPENT_TODAY_BY_TAG',
    WEEK: 'PDS.WEEK',
  },
  PM: {
    TITLE: 'PM.TITLE',
  },
  PS: {
    GLOBAL_SETTINGS: 'PS.GLOBAL_SETTINGS',
    ISSUE_INTEGRATION: 'PS.ISSUE_INTEGRATION',
    PRIVACY_POLICY: 'PS.PRIVACY_POLICY',
    PRODUCTIVITY_HELPER: 'PS.PRODUCTIVITY_HELPER',
    PROJECT_SETTINGS: 'PS.PROJECT_SETTINGS',
    PROVIDE_FEEDBACK: 'PS.PROVIDE_FEEDBACK',
    SYNC_EXPORT: 'PS.SYNC_EXPORT',
    TAG_SETTINGS: 'PS.TAG_SETTINGS',
    TOGGLE_DARK_MODE: 'PS.TOGGLE_DARK_MODE',
  },
  SCHEDULE: {
    NO_REPEATABLE_TASKS: 'SCHEDULE.NO_REPEATABLE_TASKS',
    NO_SCHEDULED: 'SCHEDULE.NO_SCHEDULED',
    NO_SCHEDULED_TITLE: 'SCHEDULE.NO_SCHEDULED_TITLE',
    REPEATED_TASKS: 'SCHEDULE.REPEATED_TASKS',
    SCHEDULED_TASKS: 'SCHEDULE.SCHEDULED_TASKS',
    SCHEDULED_TASKS_WITH_REMINDER: 'SCHEDULE.SCHEDULED_TASKS_WITH_REMINDER',
    START_TASK: 'SCHEDULE.START_TASK',
  },
  THEMES: {
    amber: 'THEMES.amber',
    blue: 'THEMES.blue',
    'blue-grey': 'THEMES.blue-grey',
    cyan: 'THEMES.cyan',
    'deep-orange': 'THEMES.deep-orange',
    'deep-purple': 'THEMES.deep-purple',
    green: 'THEMES.green',
    indigo: 'THEMES.indigo',
    'light-blue': 'THEMES.light-blue',
    'light-green': 'THEMES.light-green',
    lime: 'THEMES.lime',
    pink: 'THEMES.pink',
    purple: 'THEMES.purple',
    SELECT_THEME: 'THEMES.SELECT_THEME',
    teal: 'THEMES.teal',
    yellow: 'THEMES.yellow',
  },
  V: {
    E_1TO10: 'V.E_1TO10',
    E_DATETIME: 'V.E_DATETIME',
    E_MAX: 'V.E_MAX',
    E_MAX_LENGTH: 'V.E_MAX_LENGTH',
    E_MIN: 'V.E_MIN',
    E_MIN_LENGTH: 'V.E_MIN_LENGTH',
    E_PATTERN: 'V.E_PATTERN',
    E_REQUIRED: 'V.E_REQUIRED',
  },
  WW: {
    ADD_MORE: 'WW.ADD_MORE',
    ADD_SCHEDULED_FOR_TODAY: 'WW.ADD_SCHEDULED_FOR_TODAY',
    ADD_SCHEDULED_FOR_TOMORROW: 'WW.ADD_SCHEDULED_FOR_TOMORROW',
    ADD_SOME_TASKS: 'WW.ADD_SOME_TASKS',
    COMPLETED_TASKS: 'WW.COMPLETED_TASKS',
    ESTIMATE_REMAINING: 'WW.ESTIMATE_REMAINING',
    FINISH_DAY: 'WW.FINISH_DAY',
    FINISH_DAY_FOR_PROJECT: 'WW.FINISH_DAY_FOR_PROJECT',
    FINISH_DAY_FOR_TAG: 'WW.FINISH_DAY_FOR_TAG',
    FINISH_DAY_TOOLTIP: 'WW.FINISH_DAY_TOOLTIP',
    HELP_PROCRASTINATION: 'WW.HELP_PROCRASTINATION',
    NO_COMPLETED_TASKS: 'WW.NO_COMPLETED_TASKS',
    NO_PLANNED_TASKS: 'WW.NO_PLANNED_TASKS',
    READY_TO_WORK: 'WW.READY_TO_WORK',
    RESET_BREAK_TIMER: 'WW.RESET_BREAK_TIMER',
    TIME_ESTIMATED: 'WW.TIME_ESTIMATED',
    TODAY_REMAINING: 'WW.TODAY_REMAINING',
    WITHOUT_BREAK: 'WW.WITHOUT_BREAK',
    WORKING_TODAY: 'WW.WORKING_TODAY',
    WORKING_TODAY_ARCHIVED: 'WW.WORKING_TODAY_ARCHIVED',
  },
};
export { T };
