<div mat-dialog-content>
  @if (!(isManualPauseWork$|async)) {
  <div>
    <p
      [innerHTML]="T.F.POMODORO.FINISH_SESSION_X|translate:{nr: (currentCycle$|async)}"
    ></p>
    <p>{{T.F.POMODORO.ENJOY_YOURSELF|translate}}</p>
  </div>
  } @if ((isManualPauseWork$|async)) {
  <div>
    <p>{{T.F.POMODORO.BREAK_IS_DONE|translate}}</p>
  </div>
  }

  <div class="timer">{{currentTime$|async|msToMinuteClockString}}</div>
</div>

<div
  align="center"
  mat-dialog-actions
>
  <button
    (click)="close()"
    color="primary"
    mat-button
  >
    {{T.G.CLOSE|translate}}
  </button>

  @if ((isManualPauseBreak$|async)) {
  <button
    (click)="startBreak()"
    color="primary"
    mat-stroked-button
  >
    <mat-icon>free_breakfast</mat-icon>
    <span>{{T.F.POMODORO.START_BREAK|translate}}</span>
  </button>
  } @if (!(isManualPauseWork$|async)) {
  <button
    (click)="nextSession(true)"
    color="primary"
    mat-stroked-button
  >
    <mat-icon>skip_next</mat-icon>
    <span>{{T.F.POMODORO.SKIP_BREAK|translate}}</span>
  </button>
  } @if ((isManualPauseWork$|async)) {
  <button
    (click)="nextSession()"
    color="primary"
    mat-stroked-button
  >
    <mat-icon>play_arrow</mat-icon>
    {{T.F.POMODORO.BACK_TO_WORK|translate}}
  </button>
  }
</div>
