@if (bookmarkService.isShowBookmarks$|async) {
<div
  #bookmarkBar
  [@slide]="{value:'*',params:{elHeight:(bookmarkBarHeight)}}"
  class="global-bookmark-list-outer"
>
  @if (isDragOver) {
  <div
    @fade
    class="bgc-accent drag-over-msg"
  >
    <mat-icon>add</mat-icon>
    {{T.F.BOOKMARK.BAR.DROP|translate}}
  </div>
  }
  <div class="controls-and-list-wrapper">
    <div class="list-controls">
      @if (!isEditMode) {
      <button
        (menuClosed)="isContextMenuDisabled = false"
        (menuOpened)="isContextMenuDisabled = true"
        [matMenuTriggerFor]="menu"
        [style.pointer-events]="isContextMenuDisabled ? 'none' : 'all'"
        mat-stroked-button
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      } @if (isEditMode) {
      <button
        (click)="isEditMode=false; isContextMenuDisabled=false"
        mat-stroked-button
      >
        <mat-icon>check</mat-icon>
      </button>
      }
      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>
          <button
            (click)="openEditDialog()"
            color=""
            mat-menu-item
          >
            <mat-icon>add</mat-icon>
            {{T.F.BOOKMARK.BAR.ADD|translate}}
          </button>
          <button
            (click)="isEditMode=!isEditMode"
            color=""
            mat-menu-item
          >
            <mat-icon>edit</mat-icon>
            {{T.F.BOOKMARK.BAR.EDIT|translate}}
          </button>
        </ng-template>
      </mat-menu>
    </div>
    @let bookmarks = (bookmarkService.bookmarks$|async);
    <div
      [class.isEditMode]="isEditMode"
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="bookmarks"
      (cdkDropListDropped)="drop($event)"
      class="global-bookmark-list-inner"
    >
      @if (!bookmarks?.length) {
      <div class="msg">{{T.F.BOOKMARK.BAR.NO_BOOKMARKS|translate}}</div>
      } @for (bookmark of bookmarks; track trackByFn($index, bookmark)) {
      <div
        class="global-bookmark"
        cdkDrag
        draggable="false"
      >
        <a
          [enlargeImg]="bookmark.type==='IMG'&& bookmark.path"
          [href]="bookmark.path"
          [style.background-image]="bookmark.type==='IMG'&& 'url('+bookmark.path+')'"
          [title]="bookmark.path"
          aria-label="open global bookmark"
          bookmarkLink
          color=""
          draggable="false"
          mat-stroked-button
          target="_blank"
          type="{{bookmark.type}}"
        >
          @if (bookmark.icon) {
          <mat-icon>{{bookmark.icon}} </mat-icon>
          } {{bookmark.title}}
        </a>
        @if (isEditMode) {
        <div class="controls">
          @if (isEditMode) {
          <mat-icon
            @fade
            class="drag-handle bgc-400"
            svgIcon="drag_handle"
          ></mat-icon>
          }
          <button
            (click)="openEditDialog(bookmark)"
            aria-label="open edit dialog"
            class="edit-btn"
            color="primary"
            mat-raised-button
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            (click)="remove(bookmark.id)"
            aria-label="remove bookmark"
            class="trash-btn"
            color="warn"
            mat-raised-button
          >
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        }
      </div>
      }
    </div>
  </div>
</div>
}
