@if (task?.issueType===JIRA_TYPE) {
<jira-issue-content
  [issue]="issueData()"
  [task]="task"
></jira-issue-content>
} @if (task?.issueType===GITHUB_TYPE) {
<github-issue-content
  [issue]="issueData()"
  [task]="task"
></github-issue-content>
} @if (task?.issueType===REDMINE_TYPE) {
<redmine-issue-content
  [issue]="issueData()"
  [task]="task"
></redmine-issue-content>
} @if (task?.issueType===GITLAB_TYPE) {
<gitlab-issue-content
  [issue]="issueData()"
  [task]="task"
></gitlab-issue-content>
} @if (task?.issueType===CALDAV_TYPE) {
<caldav-issue-content
  [issue]="issueData()"
  [task]="task"
></caldav-issue-content>
} @if (task?.issueType===OPEN_PROJECT_TYPE) {
<open-project-issue-content
  [issue]="issueData()"
  [task]="task"
></open-project-issue-content>
} @if (task?.issueType===GITEA_TYPE) {
<gitea-issue-content
  [issue]="issueData()"
  [task]="task"
></gitea-issue-content>
}
