<div class="filter-bar">
  <button
    mat-icon-button
    (click)="closePanel.emit()"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
  <div class="filter-input">
    <mat-icon class="filter-ico">filter_alt</mat-icon>
    <input
      type="text"
      [formControl]="taskSuggestionsCtrl"
      placeholder="Enter text to filter"
    />
  </div>
</div>

<!-- TODO
* schedule tasks need to be indicated
-->

@if (filteredTasks$|async; as allTasks) {
<div
  class="tasks"
  cdkDropList
  cdkDropListSortingDisabled
  [cdkDropListData]="ADD_TASK_PANEL "
  (cdkDropListDropped)="drop($event)"
>
  @for (task of allTasks; track task.id) {
  <planner-task
    cdkDrag
    [cdkDragData]="task"
    [task]="task"
  ></planner-task>
  } @if (allTasks.length === 0) {
  <div class="empty">{{ T.F.PLANNER.NO_TASKS|translate }}</div>
  }
</div>
}
